import React, {
  useEffect,
  useState,
} from 'react';
import SEO from '@/common/ui/organism/SEO';
import Layout from '@/common/ui/template/Layout';
import { store } from '@/common/core/store/decorator';
import { observable, action } from 'mobx';
// 画像
import logo from '@/newcar/ui/otoshidama/images/logo.svg';
import ttl from '@/newcar/ui/otoshidama/images/ttl.svg';
import resultTtl from '@/newcar/ui/otoshidama/images/result-ttl.svg';
import fin from '@/newcar/ui/otoshidama/images/fin.svg';
import omikuji from '@/newcar/ui/otoshidama/images/omikuji.gif';
import ema from '@/newcar/ui/otoshidama/images/ema.png';
import daikichi from '@/newcar/ui/otoshidama/images/daikichi.png';
import chukichi from '@/newcar/ui/otoshidama/images/chukichi.png';
import shokichi from '@/newcar/ui/otoshidama/images/shokichi.png';
import kichi from '@/newcar/ui/otoshidama/images/kichi.png';
import suekichi from '@/newcar/ui/otoshidama/images/suekichi.png';
import carmokun from '@/newcar/ui/otoshidama/images/carmokun.png';
// スタイル
import * as styles from '@/newcar/ui/otoshidama/index.module.styl';

type ResultType = '末吉' | '小吉' | '中吉' | '吉' | '大吉';

const option = {
  header: {
    default: (
      <img
        className={`${styles.ttl}`}
        src={ttl}
        alt="今年の運勢を確認しよう"
      />
    ),
    result: (
      <img
        className={`is-margin-bottom-4 ${styles.ttl}`}
        src={resultTtl}
        alt="あなたの今年の運勢は"
      />
    ),
  },
  result: {
    大吉: {
      rate: 1,
      image: daikichi,
    },
    中吉: {
      rate: 2,
      image: chukichi,
    },
    小吉: {
      rate: 2,
      image: shokichi,
    },
    吉: {
      rate: 2,
      image: kichi,
    },
    末吉: {
      rate: 1,
      image: suekichi,
    },
  },
};

async function cryptoSha256(result: ResultType): Promise<string> {
  const uint8 = new TextEncoder().encode(result);
  const digest = await crypto.subtle.digest('SHA-256', uint8);
  return Array.from(new Uint8Array(digest)).map((v) => v.toString(16).padStart(2, '0')).join('');
}

/**
 * ハッシュ値に対するくじ引き結果のマップオブジェクトを作成
 * @return {*}  {Promise<{ [x: string]: ResultType }>}
 */
async function createHashMap(): Promise<{ [x: string]: ResultType }> {
  const hashMap: { [x: string]: ResultType } = {};
  // eslint-disable-next-line no-restricted-syntax
  for await (const key of Object.keys(option.result)) {
    const result = key as ResultType;
    const hash = await cryptoSha256(result);
    hashMap[hash] = result;
  }
  return hashMap;
}

@store({ persist: true, name: 'carmo-otoshidama-2025' })
class OtoshidamaManagerStore {
  @observable result?: string;

   /**
   * くじ引き処理
   */
   @action
  async draw(): Promise<ResultType> {
    // くじ引き処理
    const results: ResultType[] = [];
    Object.keys(option.result).forEach((key) => {
      const $key = key as ResultType;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < option.result[$key].rate; i++) {
        results.push($key);
      }
    });
    const result = results[(new Date().getMilliseconds()) % results.length];

    // MEMO: ローカルストレージを編集して結果を操作できないように難読化
    this.result = await cryptoSha256(result);

    return result;
  }
}

const $store = new OtoshidamaManagerStore();
const Freezeframe = typeof window !== 'undefined' ? require('freezeframe') : null;

export default function otoshidama(): JSX.Element {
  // SSRエラー対応
  if (typeof window === 'undefined') return <></>;

  // Create states
  const [frame, setFrame] = useState<typeof Freezeframe>();
  const [result, setResult] = useState<ResultType | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [hashMap, setHashMap] = useState<{ [x: string]: ResultType }>();

  // Create effects
  useEffect(() => {
    /* Initialize */
    createHashMap().then((value) => {
      setHashMap(value);
    });
  }, []);

  useEffect(() => {
    if (!hashMap) return;
    setLoading(false);
    setFrame(new Freezeframe('.freezeframe', { trigger: false }));
    setResult(hashMap[$store.result ?? '']);
  }, [hashMap]);

  // Handlers
  const handleClick = async () => {
    if (frame) frame.start();
    await new Promise((resolve) => setTimeout(resolve, 2600));
    setResult(await $store.draw());
  };

  if (!hashMap) {
    return (
      <Layout>
        <main className={styles.bgMv}>
          {/* Dummy */}
        </main>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO />
      <main className={styles.bgMv}>
        <div
          className={`container ${styles.container}`}
        >
          {/* 以下おみくじ */}
          <section className={result ? `has-text-centered ${styles.sectionresult}` : styles.sectionlottery}>
            <div style={{ padding: '16px 0' }}>
              {
                result && (
                  <div id="otoshidama-header">
                    <img
                      className={`is-margin-bottom-4 ${styles.carmokun}`}
                      src={carmokun}
                      alt="カルモくん"
                    />
                  </div>
                )
              }
              <div id="otoshidama-header">
                <h1>{option.header[result ? 'result' : 'default']}</h1>
              </div>
              {
                result ? (
                  <>
                    <div>
                      <img
                        className={`${styles.omikuji}`}
                        src={option.result[result].image}
                        alt={result}
                      />
                    </div>
                    <div>
                      <img className={`${styles.ttl}`} src={fin} alt="本年もよろしくお願いします" />
                    </div>
                  </>
                ) : (
                  <div className="columns is-mobile is-vcentered">
                    <div className={`column is-9 ${styles.omikuji}`}>
                      <img
                        className="freezeframe"
                        src={omikuji}
                        alt="おみくじ"
                      />
                    </div>
                    <button
                      type="button"
                      disabled={loading || (result !== undefined)}
                      className="column is-5 is-relative"
                      onClick={handleClick}
                    >
                      <img className={`${styles.ema}`} src={ema} alt="絵馬" />
                    </button>
                  </div>
                )
              }
            </div>
          </section>
        </div>
        {/* カルモロゴ */}
        <div className={`has-text-centered ${styles.logo}`}>
          <img className={`${styles.logoimg}`} src={logo} alt="定額カルモくんロゴ" />
        </div>
      </main>
    </Layout>
  );
}
