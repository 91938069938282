import React, {
  FC,
  useState,
} from 'react';
import NEWCAR_CONST from '@/newcar/util/const';

// ランキング関連画像
import arrowIconBlack from '@/newcar/ui/common/img/object/icon/arrow-icon-black.svg';

// topのstyle読込
import * as styles from '@/newcar/ui/top/template/index.module.styl';
import * as stylesRank from '@/newcar/ui/top/organism/RankingTop.module.styl';

interface RankingTopProps {
  getMinPrice: (carId: string) => string;
  getThumbnailURL: (carId: string) => string;
  getWLTC: (carId: string) => string;
}

const RankingTop:FC<RankingTopProps> = ({ getMinPrice, getThumbnailURL, getWLTC }) => {
  // 車両画像をcarDigestから取得
  const nboxThumbnail = getThumbnailURL('10013');
  const nboxWLTC = getWLTC('10013');
  const noneThumbnail = getThumbnailURL('10095');
  const noneWLTC = getWLTC('10095');
  const miraesThumbnail = getThumbnailURL('10017');
  const miraesWLTC = getWLTC('10017');
  const hustlerThumbnail = getThumbnailURL('10030');
  const hustlerWLTC = getWLTC('10030');
  const altoThumbnail = getThumbnailURL('10053');
  const altoWLTC = getWLTC('10053');
  const spaciaThumbnail = getThumbnailURL('10031');
  const spaciaWLTC = getWLTC('10031');
  const spaciacustomThumbnail = getThumbnailURL('10057');
  const spaciacustomWLTC = getWLTC('10057');
  const tantoThumbnail = getThumbnailURL('10014');
  const tantoWLTC = getWLTC('10014');
  const altolapinThumbnail = getThumbnailURL('10034');
  const altolapinWLTC = getWLTC('10034');
  const wagonrThumbnail = getThumbnailURL('10016');
  const wagonrWLTC = getWLTC('10016');
  const delicaminiThumbnail = getThumbnailURL('10212');
  const delicaminiWLTC = getWLTC('10212');
  const taftThumbnail = getThumbnailURL('10176');
  const taftWLTC = getWLTC('10176');

  // nbox
  const nboxRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.nbox}`;
  const nboxRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.nbox);
  // miraes
  const miraesRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.miraes}`;
  const miraesRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.miraes);
  // tanto
  const tantoRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.tanto}`;
  const tantoRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.tanto);
  // none
  const noneRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.none}`;
  const noneRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.none);
  // hustler
  const hustlerRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.hustler}`;
  const hustlerRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.hustler);
  // spacia
  const spaciaRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.spacia}`;
  const spaciaRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.spacia);
  // sspacia_custom
  const spaciacustomRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.spaciacustom}`;
  const spaciacustomRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.spaciacustom);
  // alt
  const altRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.alt}`;
  const altRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.alt);
  // altolapin
  const altolapinRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.altolapin}`;
  const altolapinRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.altolapin);
  // wagonr
  const wagonrRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.wagonr}`;
  const wagonrRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.wagonr);
  // delicamini
  const delicaminiRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.delicamini}`;
  const delicaminiRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.delicamini);
  // taft
  const taftRankingPath = `/detail/${NEWCAR_CONST.TOPRANKING.ID.taft}`;
  const taftRankingPrice = getMinPrice(NEWCAR_CONST.TOPRANKING.ID.taft);

  // 選択されたタブを管理するためのstateを作成
  const [selectedTab, setSelectedTab] = useState<string>('rankigRadioPopular');

  // タブのラジオボタンが変更されたときに実行されるハンドラー
  const handleTabChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTab(e.target.id);
  };

  return (
    <>

      {/* tab切り替え */}
      <section id="ranking-original" className={`${stylesRank.isPatternFourth}`}>
        <div className={`${stylesRank.rankingBackground} ${stylesRank.hasTabs}`}>
          <div className="container is-margin-auto">
            <h2 className={`is-3 has-text-centered ${styles.contentsTitle} ${styles.isRanking} ${stylesRank.rankingTitle}`}>
              定額カルモくんオススメの車ランキング
            </h2>
            <div className={`${stylesRank.rankingWrapper}`}>
              <div className={`columns is-mobile ${stylesRank.rankingTabsWrapper}`}>
                {/* tabヘッダ部分 */}
                { /* eslint-disable jsx-a11y/label-has-associated-control */ }
                <div className={`${stylesRank.rankingTabContainer}`}>
                  <input
                    id="rankigRadioPopular"
                    type="radio"
                    name="rankigRadio"
                    checked={selectedTab === 'rankigRadioPopular'}
                    onChange={handleTabChange}
                    className={`${stylesRank.isTabsFirst}`}
                    defaultChecked
                  />
                  <label htmlFor="rankigRadioPopular" className={`${stylesRank.rankingTabsLabel}`}>おすすめ</label>

                  <input
                    id="rankigRadioCheap"
                    type="radio"
                    name="rankigRadio"
                    checked={selectedTab === 'rankigRadioCheap'}
                    onChange={handleTabChange}
                    className={`${stylesRank.isTabsSecond}`}
                  />
                  <label htmlFor="rankigRadioCheap" className={`${stylesRank.rankingTabsLabel}`}>価格が安い</label>

                  <input
                    id="rankigRadioFuel"
                    type="radio"
                    name="rankigRadio"
                    checked={selectedTab === 'rankigRadioFuel'}
                    onChange={handleTabChange}
                    className={`${stylesRank.isTabsThird}`}
                  />
                  <label htmlFor="rankigRadioFuel" className={`${stylesRank.rankingTabsLabel}`}>燃費が良い</label>

                  <input
                    id="rankigRadioFamily"
                    type="radio"
                    name="rankigRadio"
                    checked={selectedTab === 'rankigRadioFamily'}
                    onChange={handleTabChange}
                    className={`${stylesRank.isTabsFourth}`}
                  />
                  <label htmlFor="rankigRadioFamily" className={`${stylesRank.rankingTabsLabel}`}>ファミリー向け</label>

                  <input
                    id="rankigRadioFamale"
                    type="radio"
                    name="rankigRadio"
                    checked={selectedTab === 'rankigRadioFamale'}
                    onChange={handleTabChange}
                    className={`${stylesRank.isTabsFifth}`}
                  />
                  <label htmlFor="rankigRadioFamale" className={`${stylesRank.rankingTabsLabel}`}>女性向け</label>

                  <input
                    id="rankigRadioBegginer"
                    type="radio"
                    name="rankigRadio"
                    checked={selectedTab === 'rankigRadioBegginer'}
                    onChange={handleTabChange}
                    className={`${stylesRank.isTabsSixth}`}
                  />
                  <label htmlFor="rankigRadioBegginer" className={`${stylesRank.rankingTabsLabel}`}>初心者向け</label>
                </div>
                {/* tabヘッダ部分 */}

                {/* tab表示要素 */}
                <div className={`container tab-element ${selectedTab === 'rankigRadioPopular' ? 'is-block' : ''} ${stylesRank.tabElement01}`} id="rankigRadioPopular_content">
                  {/* 人気ランキング */}
                  <div className={`is-relative ${stylesRank.headingContainer} ${stylesRank.isPopular}`}>
                    <span className="is-size-5 is-block">いま選ばれている車をまずはチェック！</span>
                    <h3 className="is-size-3 has-text-weight-bold">
                      おすすめランキング
                    </h3>
                  </div>
                  <div className={`columns ${stylesRank.rankingCardContainer}`}>
                    {/*  1  */}
                    <div className={`box recommend-10013 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFirst}`}>
                      <a className="recommend-url is-relative" href={nboxRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={nboxThumbnail}
                                className="recommend-img"
                                alt="N BOX"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">ホンダ</strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">N BOX</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{nboxRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {nboxWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          今日本で一番売れているベストセラーのスーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 1  */}

                    {/*  2  */}
                    <div className={`box recommend-10030 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankSecond}`}>
                      <a className="recommend-url is-relative" href={hustlerRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={hustlerThumbnail}
                                className="recommend-img"
                                alt="ハスラー"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">ハスラー</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{hustlerRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {hustlerWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          使い勝手の良さと走破性に加え、ユニークなエクステリアが魅力の大ヒット軽クロスオーバーSUV
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 2  */}
                    {/*  3  */}
                    <div className={`box recommend-10031 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankThird}`}>

                      <a className="recommend-url is-relative" href={spaciaRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={spaciaThumbnail}
                                className="recommend-img"
                                alt="スペーシア"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                スペーシア
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{spaciaRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {spaciaWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          より便利に、快適に！広い室内を活かす工夫が満載の軽スーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  3  */}

                    {/*  4  */}
                    <div className={`box recommend-10017 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFourth}`}>

                      <a className="recommend-url is-relative" href={miraesRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={miraesThumbnail}
                                className="recommend-img"
                                alt="ミライース"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                ダイハツ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                ミライース
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{miraesRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {miraesWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          ただ安いだけではない、シンプルかつシックな軽自動車のスタンダード
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  4  */}

                    {/*  5  */}
                    <div className={`box recommend-10053 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFifth}`}>

                      <a className="recommend-url is-relative" href={altRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={altoThumbnail}
                                className="recommend-img"
                                alt="アルト"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                アルト
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{altRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {altoWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          フルモデルチェンジして原点回帰したベーシック軽自動車の決定版！ハイブリッドも登場
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  5  */}
                  </div>
                  <div className="has-text-centered">
                    <a
                      className="button radius-most is-large is-fixed-width-button is-margin-bottom-7"
                      href="/docs/ranking/"
                    >
                      ランキング一覧を見る
                      <span
                        className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                      />
                    </a>
                  </div>
                </div>
                {/* // 人気ランキング */}

                {/* 価格が安いランキング */}
                <div className={`container tab-element ${selectedTab === 'rankigRadioCheap' ? 'is-block' : ''} ${stylesRank.tabElement02}`} id="rankigRadioCheap_content">
                  <div className={`is-relative ${stylesRank.headingContainer} ${stylesRank.isCost}`}>
                    <span className="is-size-5 is-block">月額料金を抑えてお得に乗るならこの車！</span>
                    <h3 className="is-size-3 has-text-weight-bold">
                      価格が安い車ランキング
                    </h3>
                  </div>
                  <div className={`columns ${stylesRank.rankingCardContainer}`}>
                    {/*  1  */}
                    <div className={`box recommend-10053 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFirst}`}>
                      <a className="recommend-url is-relative" href={altRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={altoThumbnail}
                                className="recommend-img"
                                alt="アルト"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">スズキ</strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">アルト</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{altRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {altoWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          フルモデルチェンジして原点回帰したベーシック軽自動車の決定版！ハイブリッドも登場
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 1  */}
                    {/*  2  */}
                    <div className={`box recommend-10017 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankSecond}`}>

                      <a className="recommend-url is-relative" href={miraesRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={miraesThumbnail}
                                className="recommend-img"
                                alt="ミライース"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                ダイハツ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">ミライース</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{miraesRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {miraesWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          ただ安いだけではない、シンプルかつシックな軽自動車のスタンダード
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>

                    {/* // 2  */}

                    {/*  3  */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankThird}`}>

                      <a className="recommend-url is-relative" href={wagonrRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={wagonrThumbnail}
                                className="recommend-img"
                                alt="ワゴンR"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                ワゴンR
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{wagonrRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {wagonrWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          マイルドハイブリッドを採用、低燃費と使い勝手の良さに定評のある軽ハイトワゴンの定番モデル
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  3  */}

                    {/*  4  */}
                    <div className={`box recommend-10176 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFourth}`}>

                      <a className="recommend-url is-relative" href={taftRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={taftThumbnail}
                                className="recommend-img"
                                alt="タフト"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                ダイハツ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                タフト
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{taftRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {taftWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          名前に相応しい本格的な悪路走破性を備えた軽クロスオーバーモデル
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  4  */}

                    {/*  5  */}
                    <div className={`box recommend-10034 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFifth}`}>

                      <a className="recommend-url is-relative" href={altolapinRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={altolapinThumbnail}
                                className="recommend-img"
                                alt="アルトラパン"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                アルトラパン
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{altolapinRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {altolapinWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          「まるしかくい」独特のキュートなデザインや低燃費が魅力の毎日が楽しくなる軽セダン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  5  */}
                  </div>
                  <div className="has-text-centered">
                    <a
                      className="button radius-most is-large is-fixed-width-button is-margin-bottom-7"
                      href="/docs/ranking/affordable/"
                    >
                      ランキング一覧を見る
                      <span
                        className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                      />
                    </a>
                  </div>
                </div>
                {/* // 価格が安いランキング */}

                {/* 燃費の良い車ランキング */}
                <div className={`container tab-element ${selectedTab === 'rankigRadioFuel' ? 'is-block' : ''} ${stylesRank.tabElement03}`} id="rankigRadioFuel_content">
                  <div className={`is-relative ${stylesRank.headingContainer} ${stylesRank.isFuel}`}>
                    <span className="is-size-5 is-block">維持費を抑えてコスパよく乗るならこの車！</span>
                    <h3 className="is-size-3 has-text-weight-bold">
                      燃費がいい車ランキング
                    </h3>
                  </div>
                  <div className={`columns ${stylesRank.rankingCardContainer}`}>
                    {/*  1  */}
                    <div className={`box recommend-10053 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFirst}`}>

                      <a className="recommend-url is-relative" href={altRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={altoThumbnail}
                                className="recommend-img"
                                alt="アルト"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">スズキ</strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">アルト</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{altRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {altoWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          フルモデルチェンジして原点回帰したベーシック軽自動車の決定版！ハイブリッドも登場
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 1  */}

                    {/*  2  */}
                    <div className={`box recommend-10034 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankSecond}`}>

                      <a className="recommend-url is-relative" href={altolapinRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={altolapinThumbnail}
                                className="recommend-img"
                                alt="アルトラパン"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">アルトラパン</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{altolapinRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {altolapinWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          「まるしかくい」独特のキュートなデザインや低燃費が魅力の毎日が楽しくなる軽セダン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 2  */}

                    {/*  3  */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankThird}`}>

                      <a className="recommend-url is-relative" href={wagonrRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={wagonrThumbnail}
                                className="recommend-img"
                                alt="ワゴンR"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                ワゴンR
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{wagonrRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {wagonrWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          マイルドハイブリッドを採用、低燃費と使い勝手の良さに定評のある軽ハイトワゴンの定番モデル
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  3  */}

                    {/*  4  */}
                    <div className={`box recommend-10057 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFourth}`}>

                      <a className="recommend-url is-relative" href={spaciacustomRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={spaciacustomThumbnail}
                                className="recommend-img"
                                alt="スペーシアカスタム"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                スペーシアカスタム
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{spaciacustomRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {spaciacustomWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          安全性や利便性が向上、デザインもより洗練された雰囲気に進化した軽スーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  4  */}

                    {/*  5  */}
                    <div className={`box recommend-10031 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFifth}`}>

                      <a className="recommend-url is-relative" href={spaciaRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={spaciaThumbnail}
                                className="recommend-img"
                                alt="スペーシア"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                スペーシア
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{spaciaRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {spaciaWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          より便利に、快適に！広い室内を活かす工夫が満載の軽スーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  5  */}
                  </div>
                  <div className="has-text-centered">
                    <a
                      className="button radius-most is-large is-fixed-width-button is-margin-bottom-7"
                      href="/docs/ranking/fuel_economy/"
                    >
                      ランキング一覧を見る
                      <span
                        className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                      />
                    </a>
                  </div>
                </div>
                {/* // 燃費の良い車ランキング */}

                {/* ファミリー層人気ランキング */}
                <div className={`container tab-element ${selectedTab === 'rankigRadioFamily' ? 'is-block' : ''} ${stylesRank.tabElement04}`} id="rankigRadioFamily_content">
                  <div className={`is-relative ${stylesRank.headingContainer} ${stylesRank.isFamily}`}>
                    <span className="is-size-5 is-block">車内広々、子供も大人も嬉しい機能が充実！</span>
                    <h3 className="is-size-3 has-text-weight-bold">
                      ファミリー層人気ランキング
                    </h3>
                  </div>
                  <div className={`columns ${stylesRank.rankingCardContainer}`}>
                    {/*  1  */}
                    <div className={`box recommend-10013 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFirst}`}>

                      <a className="recommend-url is-relative" href={nboxRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={nboxThumbnail}
                                className="recommend-img"
                                alt="N BOX"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">ホンダ</strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">N BOX</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{nboxRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {nboxWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          今日本で一番売れているベストセラーのスーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 1  */}

                    {/*  2  */}
                    <div className={`box recommend-10030 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankSecond}`}>

                      <a className="recommend-url is-relative" href={spaciaRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={spaciaThumbnail}
                                className="recommend-img"
                                alt="スペーシア"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">スペーシア</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{spaciaRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {spaciaWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          より便利に、快適に！広い室内を活かす工夫が満載の軽スーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 2  */}

                    {/*  3  */}
                    <div className={`box recommend-10014 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankThird}`}>

                      <a className="recommend-url is-relative" href={tantoRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={tantoThumbnail}
                                className="recommend-img"
                                alt="タント"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                ダイハツ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                タント
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{tantoRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {tantoWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          「ミラクルオープンドア」が実現する乗降性の良さが魅力。ファミリーに大人気の軽スーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  3  */}

                    {/*  4  */}
                    <div className={`box recommend-10030 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFourth}`}>

                      <a className="recommend-url is-relative" href={hustlerRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={hustlerThumbnail}
                                className="recommend-img"
                                alt="ハスラー"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                ハスラー
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{hustlerRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {hustlerWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          使い勝手の良さと走破性に加え、ユニークなエクステリアが魅力の大ヒット軽クロスオーバーSUV
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  4  */}

                    {/*  5 */}
                    <div className={`box recommend-10212 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFifth}`}>

                      <a className="recommend-url is-relative" href={delicaminiRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={delicaminiThumbnail}
                                className="recommend-img"
                                alt="デリカミニ"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                三菱
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                デリカミニ
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{delicaminiRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {delicaminiWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          名車「デリカ」の名を冠した、コワモテ顔のタフなスタイルが魅力の軽スーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  5  */}
                  </div>
                  <div className="has-text-centered">
                    <a
                      className="button radius-most is-large is-fixed-width-button is-margin-bottom-7"
                      href="/docs/ranking/family_cars/"
                    >
                      ランキング一覧を見る
                      <span
                        className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                      />
                    </a>
                  </div>
                </div>
                {/* // ファミリー層人気ランキング */}

                {/* 女性向けランキング */}
                <div className={`container tab-element ${selectedTab === 'rankigRadioFamale' ? 'is-block' : ''} ${stylesRank.tabElement05}`} id="rankigRadioFamale_content">
                  <div className={`is-relative ${stylesRank.headingContainer} ${stylesRank.isWoman}`}>
                    <span className="is-size-5 is-block">価格も燃費も安全性能も運転しやすさも◎！</span>
                    <h3 className="is-size-3 has-text-weight-bold">
                      女性向けの車ランキング
                    </h3>
                  </div>
                  <div className={`columns ${stylesRank.rankingCardContainer}`}>
                    {/*  1  */}
                    <div className={`box recommend-10031 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFirst}`}>

                      <a className="recommend-url is-relative" href={spaciaRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={spaciaThumbnail}
                                className="recommend-img"
                                alt="スペーシア"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">スズキ</strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">スペーシア</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{spaciaRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {spaciaWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          より便利に、快適に！広い室内を活かす工夫が満載の軽スーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 1  */}

                    {/*  2  */}
                    <div className={`box recommend-10030 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankSecond}`}>

                      <a className="recommend-url is-relative" href={hustlerRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={hustlerThumbnail}
                                className="recommend-img"
                                alt="ハスラー"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">ハスラー</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{hustlerRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {hustlerWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          使い勝手の良さと走破性に加え、ユニークなエクステリアが魅力の大ヒット軽クロスオーバーSUV
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 2  */}

                    {/*  3  */}
                    <div className={`box recommend-10013 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankThird}`}>

                      <a className="recommend-url is-relative" href={nboxRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={nboxThumbnail}
                                className="recommend-img"
                                alt="N BOX"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                ホンダ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                N BOX
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{nboxRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {nboxWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          今日本で一番売れているベストセラーのスーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  3  */}

                    {/*  4  */}
                    <div className={`box recommend-10095 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFourth}`}>

                      <a className="recommend-url is-relative" href={noneRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={noneThumbnail}
                                className="recommend-img"
                                alt="N ONE"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                ホンダ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                N-ONE
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{noneRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {noneWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          愛着のわくユニークなデザインが魅力。末永く愛される利便性の高さと走行性能を備えたこだわりの1台
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  4  */}

                    {/*  5  */}
                    <div className={`box recommend-10034 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFifth}`}>

                      <a className="recommend-url is-relative" href={altolapinRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={altolapinThumbnail}
                                className="recommend-img"
                                alt="アルトラパン"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                アルトラパン
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{altolapinRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {altolapinWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          「まるしかくい」独特のキュートなデザインや低燃費が魅力の毎日が楽しくなる軽セダン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  5  */}
                  </div>
                  <div className="has-text-centered">
                    <a
                      className="button radius-most is-large is-fixed-width-button is-margin-bottom-7"
                      href="/docs/ranking/for_women/"
                    >
                      ランキング一覧を見る
                      <span
                        className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                      />
                    </a>
                  </div>
                </div>
                {/* // 女性向けランキング */}

                {/* 初心者向けランキング */}
                <div className={`container tab-element ${selectedTab === 'rankigRadioBegginer' ? 'is-block' : ''} ${stylesRank.tabElement06}`} id="rankigRadioBegginer_content">
                  <div className={`is-relative ${stylesRank.headingContainer} ${stylesRank.isBeginner}`}>
                    <span className="is-size-5 is-block">安全装備も充実、運転のしやすさも抜群！</span>
                    <h3 className="is-size-3 has-text-weight-bold">
                      初心者向けの車ランキング
                    </h3>
                  </div>
                  <div className={`columns ${stylesRank.rankingCardContainer}`}>
                    {/*  1  */}
                    <div className={`box recommend-10013 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFirst}`}>

                      <a className="recommend-url is-relative" href={nboxRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={nboxThumbnail}
                                className="recommend-img"
                                alt="N BOX"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">ホンダ</strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">N BOX</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{nboxRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {nboxWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          今日本で一番売れているベストセラーのスーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 1  */}

                    {/*  2  */}
                    <div className={`box recommend-10014 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankSecond}`}>

                      <a className="recommend-url is-relative" href={tantoRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={tantoThumbnail}
                                className="recommend-img"
                                alt="タント"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                ダイハツ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">タント</strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{tantoRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {tantoWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          「ミラクルオープンドア」が実現する乗降性の良さが魅力。ファミリーに大人気の軽スーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* // 2  */}

                    {/*  3  */}
                    <div className={`box recommend-10031 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankThird}`}>

                      <a className="recommend-url is-relative" href={spaciaRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={spaciaThumbnail}
                                className="recommend-img"
                                alt="スペーシア"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                スペーシア
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{spaciaRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {spaciaWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          より便利に、快適に！広い室内を活かす工夫が満載の軽スーパーハイトワゴン
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  3  */}

                    {/*  4  */}
                    <div className={`box recommend-10030 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFourth}`}>

                      <a className="recommend-url is-relative" href={hustlerRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={hustlerThumbnail}
                                className="recommend-img"
                                alt="ハスラー"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                ハスラー
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{hustlerRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {hustlerWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          使い勝手の良さと走破性に加え、ユニークなエクステリアが魅力の大ヒット軽クロスオーバーSUV
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  4  */}

                    {/*  5  */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFifth}`}>

                      <a className="recommend-url is-relative" href={wagonrRankingPath}>
                        <div className={stylesRank.rankingHeader}>
                          <div className={`card-image ${stylesRank.isNoneFlexGrow}`}>
                            <figure className={`image ${stylesRank.rankingThumbnail}`}>
                              <img
                                src={wagonrThumbnail}
                                className="recommend-img"
                                alt="ワゴンR"
                                width="158"
                                height="89"
                                loading="lazy"
                              />
                            </figure>
                          </div>
                          <div className={`column ${stylesRank.carNameContainer}`}>
                            <div className="is-size-5 has-text-black-ter">
                              <strong className="recommend-maker-name">
                                スズキ
                              </strong>
                            </div>
                            <div className="is-size-4 has-text-black-ter">
                              <strong className="recommend-name">
                                ワゴンR
                              </strong>
                            </div>
                            <div className="is-size-3 has-text-black-ter">
                              <strong className="lowest-price">{wagonrRankingPrice}</strong>
                              <span className="is-size-6 has-text-grey-light">
                                円～/月
                              </span>
                            </div>
                            <div>
                              <span className="is-size-7 has-text-grey ranking-tag">
                                頭金0円
                              </span>
                              <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                ボーナス払い0円
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={stylesRank.rankingSpec}>
                          <li>
                            燃費（WLTCモード）：
                            {wagonrWLTC}
                            Km/L
                          </li>
                          <li>定員：４人以下</li>
                        </ul>
                        <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                          マイルドハイブリッドを採用、低燃費と使い勝手の良さに定評のある軽ハイトワゴンの定番モデル
                        </div>
                        <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                          <img src={arrowIconBlack} alt="矢印アイコン" width="16" height="16" loading="lazy" />
                        </i>
                      </a>
                    </div>
                    {/* //  5  */}
                  </div>
                  <div className="has-text-centered">
                    <a
                      className="button radius-most is-large is-fixed-width-button is-margin-bottom-7"
                      href="/docs/ranking/for_beginners/"
                    >
                      ランキング一覧を見る
                      <span
                        className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                      />
                    </a>
                  </div>
                </div>
                {/* // 初心者向けランキング */}

                {/* // tab表示要素 */}

              </div>

            </div>
          </div>
        </div>
      </section>
      {/* //tab切り替え */}
    </>
  );
};

export default RankingTop;
