/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { PageProps } from 'gatsby';
import queryString from 'query-string';

import { Header } from '@/newcar/ui/contract/components';
import Layout from '@/common/ui/template/Layout';
import SEO from '@/common/ui/organism/SEO';
import { Helmet } from 'react-helmet';
import Form from '@/newcar/ui/contract/organism/Form';
import { simulationManagerStore, simulationManagerStore as dummySimulationManagerStore } from '@/newcar/core/store/simulationManagerStore';
import SearchCarStoreContext from '@/newcar/ui/common/context/searchCarStoreContext';
import VehicleGrade from '@/common/core/model/catalog/vehicleGrade';
import NEWCAR_CONST from '@/newcar/util/const';
import { VehicleTypes } from '@/common/core/model/catalog/vehicleTypes';

import {
  ContractType,
} from '@/newcar/util/const/contract';

import {
  META_TITLES,
  META_DESCRIPTION,
} from '@/newcar/ui/contract/config';
import { setAllList } from '@/newcar/util/setAllList';
import CarDigest from '@/common/core/model/carDigest';
import CARMO_CONST from '@/common/util/const';

export interface ContractProps {
  type: ContractType;
  digests: CarDigest[] | undefined;
  vehicleTypes: VehicleTypes[] | undefined;
}

// 申請フォーム ご連絡先
const Page: FC<PageProps<{}, ContractProps>> = ({ pageContext }) => {
  const {
    type,
    digests,
    vehicleTypes,
  } = pageContext;
  const searchCarStore = useContext(SearchCarStoreContext);

  if (type === 'contact_info') {
    // 商品データを再設定する
    setAllList();
  }

  const [isHousingTest, setHousingTest] = useState<boolean>();
  const [isNonActiveCtaTest, setNonActiveCtaTest] = useState<boolean>();

  useEffect(() => {
    // クエリがある場合は車両情報作成
    if (window && type === 'contact_info' && simulationManagerStore && dummySimulationManagerStore) {
      const query = queryString.parse(window.location.search);
      if (query.carid || query.carId) {
        const carId = query.carid || query.carId;
        // priceが存在せず、かつlowestが指定されている場合
        if ((!query.price && query.lowest) && typeof carId === 'string' && vehicleTypes !== undefined && digests !== undefined) {
          // 該当車種を取得
          const vehicleType = vehicleTypes.find((e) => e.id.toString() === carId);
          if (vehicleType !== undefined) {
            // 最安値のグレード
            const minPriceGrade = vehicleType.vehicleGrades
              ?.filter((vehicleGrade: VehicleGrade) => vehicleGrade.leasingFee132)
              .reduce((a, b) => (a.leasingFee132 && b.leasingFee132 && a.leasingFee132 > b.leasingFee132 ? b : a));
            const digest = digests.find((e) => e.id__normalized.toString() === carId);
            if (minPriceGrade && digest !== undefined) {
              // 最安のシミュレーションをセット
              digest.makerName = CARMO_CONST.MAKER.NAME(digest.makerId);
              searchCarStore.setAllCarDigests([digest]);
              dummySimulationManagerStore.changeId(carId);
              dummySimulationManagerStore.startSimulation(carId);
              dummySimulationManagerStore.setGrade(minPriceGrade);
              dummySimulationManagerStore.changeCarNavi('100001'); // カーナビOFF
              dummySimulationManagerStore.changeWantCar('no'); // もらえるオプションOFF
              dummySimulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL);
              console.log('dummySimulationManagerStore', dummySimulationManagerStore);
              // undefinedになる場合、結果としてシンプルプランの料金が入る
              console.log('query.price', dummySimulationManagerStore.nowSelectPrice?.toString());
              query.price = dummySimulationManagerStore.nowSelectPrice?.toString();
            }
          }
        }
        console.log('query', query);
        simulationManagerStore.createCampaignBreakdownFromQuery(query);
      }
    }
  }, []);

  // ABテスト実施用クリックイベント、VWOから発火する
  const handleHousingClick = () => {
    setHousingTest(true);
  };

  const handleNonActiveCtaClick = () => {
    setNonActiveCtaTest(true);
  };

  return (
    <Layout>
      <SEO title={META_TITLES[type]} description={META_DESCRIPTION[type]} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <Form
        type={type}
        isHousingTest={isHousingTest}
        isNonActiveCtaTest={isNonActiveCtaTest}
      />
      <button id="housing_abtest" type="button" hidden onClick={handleHousingClick}>
        radioPosition
      </button>
      <button id="nonactive_cta_abtest" type="button" hidden onClick={handleNonActiveCtaClick}>
        nonActiveCta
      </button>
    </Layout>
  );
};
export default Page;
