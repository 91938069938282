import React, {
  FC, useState, useEffect,
} from 'react';
import { Link } from 'gatsby';
import NEWCAR_DEFINE from '@/newcar/util/define';
import * as styles from '@/newcar/ui/common/molecule/HeaderMegaMenu.module.styl';

interface MegaMenuContents {
  serviceContent?: JSX.Element;
  searchContent?: JSX.Element;
  companyContent?: JSX.Element;
  otherContent?: JSX.Element;
  contractorContent?: JSX.Element;
}

interface MegaMenuStateOpen {
  isActive: boolean,
}
interface MegaMenuStateGroup {
  isGroup: '' | 'service' | 'search' | 'company' | 'other' | 'contractor',
}

// HeaderMegaMenu_PC版で表示されるヘッダーメガメニュー
const HeaderMegaMenu: FC = () => {
  // コンテンツ: ヘッダメニューhover時に対応するグループの要素をメガメニューに入れる
  const megaMenuArray: ReadonlyArray<Readonly<MegaMenuContents>> = [
    {
      serviceContent: (
        <>
          <a
            href={NEWCAR_DEFINE.PATH.ABOUT_CARMO}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-about"
          >
            定額カルモくんとは？
          </a>
          <a
            href={NEWCAR_DEFINE.PATH.FEE}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-fee"
          >
            料金について
          </a>
          <a
            href={NEWCAR_DEFINE.PATH.MAINTENANCE}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-maintenance"
          >
            メンテナンスプラン
          </a>
          <a
            href={NEWCAR_DEFINE.PATH.MORAERU}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-moraeru"
          >
            もらえるオプション
          </a>
          <a
            href={NEWCAR_DEFINE.PATH.CAR_INSURANCE}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-carinsurance"
          >
            自動車保険
          </a>
          <a
            href={NEWCAR_DEFINE.PATH.CONTRACT_FLOW}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-docs-contract"
          >
            ご納車までのながれ
          </a>
          <a
            href={NEWCAR_DEFINE.PATH.STAFF}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-staff"
          >
            スタッフ紹介
          </a>
          <a
            href={NEWCAR_DEFINE.PATH.USERVOICE}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-uservoice"
          >
            お客様の声
          </a>
        </>
      ),
    },
    {
      searchContent: (
        <>
          <Link
            to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-newcar-search"
          >
            新車一覧から探す
          </Link>
          <Link
            to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP_PRICE1}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-newcar-search-price1"
          >
            1万円台のおクルマ
          </Link>
          <Link
            to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP_PRICE2}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-newcar-search-price2"
          >
            2万円台のおクルマ
          </Link>
          <Link
            to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP_PRICE3}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-newcar-search-price3"
          >
            3万円台のおクルマ
          </Link>
        </>
      ),
    },
    {
      companyContent: (
        <>
          <a
            href={NEWCAR_DEFINE.PATH.COMPANY_ABOUT}
            target="_blank"
            rel="noreferrer"
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-docs-company"
          >
            会社概要
          </a>
          <a
            href={NEWCAR_DEFINE.PATH.MEDIA}
            target="_blank"
            rel="noreferrer"
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-column-media"
          >
            メディア掲載情報
          </a>
          <a
            href={NEWCAR_DEFINE.PATH.SOCIAL_CONTRIBUTIONS}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-social-contributions"
          >
            社会貢献活動
          </a>
        </>
      ),
    },
    {
      otherContent: (
        <>
          <Link
            to={NEWCAR_DEFINE.PATH.FAQ}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-faq"
          >
            よくあるご質問
          </Link>
          <Link
            to={NEWCAR_DEFINE.PATH.NURTURING_LINK}
            target="_blank"
            rel="noreferrer"
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-nurturing"
          >
            資料請求
          </Link>
          <Link
            to={NEWCAR_DEFINE.PATH.CONTACT}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-contact"
          >
            お問合せ
          </Link>
          <a
            href={NEWCAR_DEFINE.PATH.CORPORATE_GOOGLE_FORM}
            target="_blank"
            rel="noreferrer"
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-corporate-form"
          >
            法人・個人事業主のお客様
          </a>
        </>
      ),
    },
    {
      contractorContent: (
        <>
          <a
            href={NEWCAR_DEFINE.PATH.APP_REASSURANCE}
            className={styles.menu_mega_content_link}
            data-gtm="gtm-newcar-navbar-app"
          >
            定額カルモくんアプリ
          </a>
        </>
      ),
    },
  ];

  // メガメニューの開閉用
  const [stateMegaMenu, setStateMegaMenuOpen] = useState<MegaMenuStateOpen>({
    isActive: false,
  });

  // どのメニューがホバーされたか分岐
  const [stateMegaMenuGroup, setStateMegaMenuGroup] = useState<MegaMenuStateGroup>({
    isGroup: '',
  });

  // メガメニューを開いてグループごとに値を入れる用
  const addClassMegaMenu = (openState:boolean, groupName:MegaMenuStateGroup['isGroup']): void => {
    setStateMegaMenuOpen({
      isActive: openState,
    });
    setStateMegaMenuGroup({
      isGroup: groupName,
    });
  };

  // メガメニューを閉じる用
  const removeClassMegaMenu = (): void => {
    setStateMegaMenuOpen({
      isActive: false,
    });
  };

  useEffect(() => {
    const menuItemLink = Array.from(document.querySelectorAll<HTMLAnchorElement>('a[data-group]'));
    const menuItemLinkNormal = Array.from(document.querySelectorAll<HTMLAnchorElement>('#navbarItemContainer a:not([data-group])'));
    const targetMegaMenu = document.querySelector('#megaMenuContainer');
    const targetMegaMenuBackground = document.querySelector('#megaMenuBackground');

    menuItemLink.forEach((e) => {
      // メガメニューを開く箇所はaタグのリンクをpreventDefaultする
      e.addEventListener('click', (clicked:MouseEvent) => {
        clicked.preventDefault();

        // data属性を取得して格納
        const nowHoverGroupName = (e.dataset.group as string);

        if (nowHoverGroupName === 'service') {
          // 第一引数:メガメニュー開閉, 第二引数:クリックされたメニュー
          addClassMegaMenu(true, 'service');
        } else if (nowHoverGroupName === 'search') {
          addClassMegaMenu(true, 'search');
        } else if (nowHoverGroupName === 'company') {
          addClassMegaMenu(true, 'company');
        } else if (nowHoverGroupName === 'other') {
          addClassMegaMenu(true, 'other');
        } else if (nowHoverGroupName === 'contractor') {
          addClassMegaMenu(true, 'contractor');
        }
      });

      e.addEventListener('mouseover', () => {
        // data属性を取得して格納
        const nowHoverGroupName = (e.dataset.group as string);

        if (nowHoverGroupName === 'service') {
          // 第一引数:メガメニュー開閉, 第二引数:ホバーされたメニュー
          addClassMegaMenu(true, 'service');
        } else if (nowHoverGroupName === 'search') {
          addClassMegaMenu(true, 'search');
        } else if (nowHoverGroupName === 'company') {
          addClassMegaMenu(true, 'company');
        } else if (nowHoverGroupName === 'other') {
          addClassMegaMenu(true, 'other');
        } else if (nowHoverGroupName === 'contractor') {
          addClassMegaMenu(true, 'contractor');
        }
      });
    });

    // クリーンアップするためアローではなく命名しておく
    const targetMegaMenuHidden = () => {
      removeClassMegaMenu();
    };
    const keyDownArrow = (e:KeyboardEvent) => {
      if (e.code === 'ArrowDown') {
        removeClassMegaMenu();
      }
    };
    const touchMegaMenuHidden = () => {
      removeClassMegaMenu();
    };

    if (stateMegaMenu.isActive === false) {
      // マウスホイールや下矢印キーが押された時にはメガメニューを閉じる
      document.addEventListener('wheel', targetMegaMenuHidden);
      document.addEventListener('keydown', keyDownArrow);
      if (window.matchMedia('(min-width: 1024px)').matches) {
        // iPadでは上記イベントが起きないのでtouchmoveで判定
        if (targetMegaMenuBackground) {
          targetMegaMenuBackground.addEventListener('touchmove', touchMegaMenuHidden);
        }
        // メガメニュー上でもスワイプできるためそれ自体にも設定
        if (targetMegaMenu) {
          targetMegaMenu.addEventListener('touchmove', touchMegaMenuHidden);
        }
      }
    }

    // メガメニューを持たないメニュー項目はisActiveステートをfalseにする
    if (menuItemLinkNormal) {
      menuItemLinkNormal.forEach((e) => {
        e.addEventListener('mouseover', targetMegaMenuHidden);
      });
    }

    // メガメニュー自体からマウスが離れた際もisActiveステートをfalseにする
    if (targetMegaMenu) {
      targetMegaMenu.addEventListener('mouseleave', targetMegaMenuHidden);
    }

    // メガメニュー背景クリックで非表示
    if (targetMegaMenuBackground) {
      targetMegaMenuBackground.addEventListener('click', targetMegaMenuHidden);
    }

    return () => {
      // クリーンアップできるイベントはクリーンアップする
      document.removeEventListener('wheel', targetMegaMenuHidden);
      document.removeEventListener('keydown', keyDownArrow);
      document.removeEventListener('touchmove', touchMegaMenuHidden);

      if (menuItemLinkNormal) {
        menuItemLinkNormal.forEach((e) => {
          e.removeEventListener('mouseover', targetMegaMenuHidden);
        });
      }
      if (targetMegaMenu) {
        targetMegaMenu.removeEventListener('mouseleave', targetMegaMenuHidden);
      }
      if (targetMegaMenuBackground) {
        targetMegaMenuBackground.removeEventListener('click', targetMegaMenuHidden);
      }
      if (targetMegaMenu) {
        targetMegaMenu.removeEventListener('touchmove', touchMegaMenuHidden);
      }
    };
  }, []);

  return (
    <div id="navbarItemContainer" className={`${styles.menu_desktop} navbar-item navbar-item-container is-hoverable has-dropdown has-text-black-ter`}>
      <a
        className={`${styles.menu_item_link} navbar-item`}
        href={NEWCAR_DEFINE.PATH.FIRST_GUIDE}
        data-gtm="gtm-newcar-navbar-first-guide"
      >
        はじめての方へ
      </a>
      <Link
        className={`${styles.menu_item_link} ${stateMegaMenu.isActive ? `${styles.isOpen}` : ''} navbar-item`}
        to="#service"
        data-group="service"
      >
        サービス内容
        <span
          className={`
                    ${styles.menu_item_link_hovered}
                    ${stateMegaMenuGroup.isGroup === 'service' && stateMegaMenu.isActive ? `${styles.isVisible}` : ''}
                  `}
        />
      </Link>
      <Link
        className={`${styles.menu_item_link} ${stateMegaMenu.isActive ? `${styles.isOpen}` : ''} navbar-item`}
        to="#search"
        data-group="search"
      >
        新車を探す
        <span
          className={`
                    ${styles.menu_item_link_hovered}
                    ${stateMegaMenuGroup.isGroup === 'search' && stateMegaMenu.isActive ? `${styles.isVisible}` : ''}
                  `}
        />
      </Link>
      <a
        className={`${styles.menu_item_link} navbar-item`}
        href={NEWCAR_DEFINE.PATH.USEDCAR_TOP}
        data-gtm="gtm-newcar-navbar-usedcar-search"
      >
        中古車を探す
      </a>
      <Link
        className={`${styles.menu_item_link} navbar-item`}
        to="/information/"
        data-gtm="gtm-newcar-navbar-information"
      >
        お知らせ一覧
      </Link>
      <a
        className={`${styles.menu_item_link} navbar-item`}
        href={NEWCAR_DEFINE.PATH.CAMPAIGN_NOW}
        data-gtm="gtm-newcar-navbar-campaigns"
      >
        キャンペーン
      </a>
      <Link
        className={`${styles.menu_item_link} ${stateMegaMenu.isActive ? `${styles.isOpen}` : ''} navbar-item`}
        to="#contractor"
        data-group="contractor"
      >
        ご契約中の方
        <span
          className={`
                    ${styles.menu_item_link_hovered}
                    ${stateMegaMenuGroup.isGroup === 'contractor' && stateMegaMenu.isActive ? `${styles.isVisible}` : ''}
                  `}
        />
      </Link>
      <Link
        className={`${styles.menu_item_link} ${stateMegaMenu.isActive ? `${styles.isOpen}` : ''} navbar-item`}
        to="#company"
        data-group="company"
      >
        運営会社
        <span
          className={`
                    ${styles.menu_item_link_hovered}
                    ${stateMegaMenuGroup.isGroup === 'company' && stateMegaMenu.isActive ? `${styles.isVisible}` : ''}
                  `}
        />
      </Link>
      <Link
        className={`${styles.menu_item_link} ${stateMegaMenu.isActive ? `${styles.isOpen}` : ''} navbar-item`}
        to="#other"
        data-group="other"
      >
        よくある質問・資料請求・お問合せ
        <span
          className={`
                    ${styles.menu_item_link_hovered}
                    ${stateMegaMenuGroup.isGroup === 'other' && stateMegaMenu.isActive ? `${styles.isVisible}` : ''}
                  `}
        />
      </Link>

      {/* メガメニュー本体 */}
      <div id="megaMenuContainer" className={`${styles.menu_mega_container} navbar-megamenu ${stateMegaMenu.isActive ? `${styles.isOpen}` : ''}`}>
        <div className={styles.menu_mega_hover_space} />
        <div className={styles.menu_mega_content_container}>
          <div className={styles.menu_mega_content_inner}>
            <div className={styles.menu_mega_content_items}>
              <span
                className={styles.menu_mega_content_close}
                onClick={removeClassMegaMenu}
                role="button"
                tabIndex={0}
              >
                閉じる
              </span>
              {
                // 取得したグループ名に基づくコンテンツが入る
                (() => {
                  if (stateMegaMenuGroup.isGroup === 'service') {
                    return (
                      megaMenuArray.map((content, index) => (
                        <div
                          key={`service${index + 1}`}
                          className={styles.menu_mega_content_items_child}
                        >
                          {content.serviceContent}
                        </div>
                      ))
                    );
                  }
                  if (stateMegaMenuGroup.isGroup === 'search') {
                    return (
                      megaMenuArray.map((content, index) => (
                        <div
                          key={`search${index + 1}`}
                          className={styles.menu_mega_content_items_child}
                        >
                          {content.searchContent}
                        </div>
                      ))
                    );
                  }
                  if (stateMegaMenuGroup.isGroup === 'company') {
                    return (
                      megaMenuArray.map((content, index) => (
                        <div
                          key={`company${index + 1}`}
                          className={styles.menu_mega_content_items_child}
                        >
                          {content.companyContent}
                        </div>
                      ))
                    );
                  }
                  if (stateMegaMenuGroup.isGroup === 'other') {
                    return (
                      megaMenuArray.map((content, index) => (
                        <div
                          key={`other${index + 1}`}
                          className={styles.menu_mega_content_items_child}
                        >
                          {content.otherContent}
                        </div>
                      ))
                    );
                  }
                  if (stateMegaMenuGroup.isGroup === 'contractor') {
                    return (
                      megaMenuArray.map((content, index) => (
                        <div
                          key={`contractor${index + 1}`}
                          className={styles.menu_mega_content_items_child}
                        >
                          {content.contractorContent}
                        </div>
                      ))
                    );
                  }
                  return false;
                })()
              }
            </div>
          </div>
        </div>
      </div>
      {/* //_メガメニュー本体 */}

      {/* メガメニュー背景 */}
      <div id="megaMenuBackground" className={`${styles.menu_mega_background} ${stateMegaMenu.isActive ? `${styles.isOpen}` : ''}`} />
      {/* //_メガメニュー背景 */}
    </div>
  );
};

export default HeaderMegaMenu;
