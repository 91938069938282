/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import CARMO_CONST from '@/common/util/const';
import checkConvertibleToShiftJIS from '@/common/util/checkConvertibleToShiftJIS';
import NEWCAR_CONST from '@/newcar/util/const';
import { IMaintenancePlan } from '@/newcar/core/model/maintenance';
import Util from '@/common/util/util';

/** カルモ定数 */
namespace NEWCAR_DEFINE {
  /** Path、ルーティング */
  export namespace PATH {

    /** 総合TOP/ ルーティング */
    export const TOP = '/';
    /** TOP URI/ルーティング */
    export const LINEUP_TOP = '/lineup/';

    /** 総合TOP/ ルーティング */
    export const USEDCAR_TOP = '/usedcar/';
    /** TOP URI/ルーティング 中古車 */
    export const USEDCAR_LINEUP_TOP = '/usedcar/lineup/';

    // /** 詳細検索 URI/ルーティング */
    // export const SEARCH = "/search";
    /** 検索結果 汎用 URI/ルーティング */
    export const PANEL_ROUTING = `${LINEUP_TOP}:makerId?/:bodyId?/`;
    // /** 検索結果 メーカー/ボディタイプ ルーティング */
    // export const MAKER_ROUTING = "/lineup/:id";
    /** 検索結果 メーカー/ボディタイプ ルーティング */
    export const LIST_ROUTING = `${LINEUP_TOP}list/`;
    /** クイック審査 */
    export const QUICK_APPLICATION = '/quick/:carId/';
    /** フォーム連絡先 ルーティング */
    export const FORM_CONTACT_INFO = '/contract/contact_info/';
    /** フォーム個人情報 ルーティング */
    export const FORM_PERSONAL_INFO = '/contract/personal_info/';
    /** フォーム審査 */
    export const FORM_REVIEW_INFO = '/contract/review_info/';
    /** フォーム確認 */
    export const FORM_CONFIRM = '/contract/confirm/';
    /** フォーム完了 */
    export const FORM_COMPLETION = '/contract/provisional_completion/';
    /** フォームエラー */
    export const FORM_ERROR = '/contract/server_error/';
    /** 契約同意画面 */
    export const AGREEMENT = '/agreement/:estimatesID/:urlID/:urlHash/';

    /** キャンペーン系 */
    /** 150万キャンペーンくじ引き説明画面 */
    export const CP_150MAN_INFO = '/150man/info/';
    /** 150万キャンペーンくじ引き結果画面 */
    export const CP_150MAN_RESULT = '/150man/number/';
    /** ぬいぐるみキャンペーンくじ引き結果画面 */
    export const CP_MASCOT_RESULT = '/carmokun2022/number/';
    /** 2022, 10万キャッシュバックキャンペーン */
    export const CP_2022_CARMOKUN = '/pr/2022/carmokun/';
    /** 2022, 定額カルモくん申込み10万人突破！ありがとうキャンペーン */
    export const CP_2022_100K = '/pr/2022/100K/';
    /** 2022, 10人に1人必ず当たる！月額料金1か月分の半額還元キャンペーン */
    export const CP_2022_HALF = '/pr/2022/half-price/';
    /** 最大3万円キャッシュバックが当たる！秋の大感謝祭2022 */
    export const CP_2022_THANKSGIVING = '/pr/2022/thanksgiving/';

    /** 検索結果 メーカー URI
     * @export
     * @param {MAKER.ID} makerId メーカーID
     * @returns URI
     */
    export function MAKER_PATH(makerId: CARMO_CONST.MAKER.ID): string {
      return `${LINEUP_TOP}${CARMO_CONST.MAKER.ID_TO_KEY(makerId)}/`;
    }
    /** 検索結果 メーカー URI
     * @export
     * @param {MAKER.ID} makerId メーカーID
     * @returns URI
     */
    export function USEDCAR_MAKER_PATH(makerId: CARMO_CONST.MAKER.ID): string {
      return `${USEDCAR_LINEUP_TOP}${CARMO_CONST.MAKER.ID_TO_KEY(makerId)}/`;
    }
    /** 検索結果 ボディタイプ URI
     * @export
     * @param {BODY_TYPE.ID} bodyId ボディタイプID
     * @returns URI
     */
    export function BODY_TYPE_PATH(bodyId: CARMO_CONST.BODY_TYPE.ID): string {
      return `${LINEUP_TOP}${CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyId)}/`;
    }
    /** 検索結果 ボディタイプ URI 中古車
     * @export
     * @param {BODY_TYPE.ID} bodyId ボディタイプID
     * @returns URI
     */
    export function USEDCAR_BODY_TYPE_PATH(bodyId: CARMO_CONST.BODY_TYPE.ID): string {
      return `${USEDCAR_LINEUP_TOP}${CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyId)}/`;
    }
    /** 検索結果 メーカー＋ボディタイプ ルーティング */
    export const MAKER_BODYTYPE_ROUTING = '/lineup/:makerId/:bodyId';
    /** 検索結果 メーカー＋ボディタイプ URI
     * @export
     * @param {MAKER.ID} makerId メーカーID
     * @param {BODY_TYPE.ID} bodyId ボディタイプID
     * @returns URI
     */
    export function MAKER_BODYTYPE(
      makerId: CARMO_CONST.MAKER.ID,
      bodyId: CARMO_CONST.BODY_TYPE.ID,
    ): string {
      return (
        `${LINEUP_TOP
        }${
          CARMO_CONST.MAKER.ID_TO_KEY(makerId)
        }/${
          CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyId) ? `${CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyId)}/` : ''
        }`
      );
    }
    /** 詳細 ルーティング */
    export const DETAIL_ROUTING = '/detail/:carId(\\d+)';
    /** 詳細 過去分 */
    export const DETAIL_ROUTING_OLD = '/detail/:carId(\\d+)/*';
    /** 詳細 URI
     * @export
     * @param {string} carId 車種ID
     * @returns URI
     */
    export function DETAIL(carId: string): string {
      return `/detail/${carId}/`;
    }
    /** お気に入り、閲覧履歴 ルーティング */
    export const USERPAGE_ROUTING = '/userpage/';
    /** シミュレーション結果一覧 ルーティング */
    export const SIMULATION_ROUTING = '/simulation/';
    /** キャンペーン一覧 ルーティング */
    export const CAMPAIGNS = '/campaigns/';
    /** 開催中のキャンペーン ルーティング */
    export const CAMPAIGN_NOW = '/pr/2025/newyear/';
    /** お客様の声 */
    export const USERVOICE = '/uservoice/';
    /** 取次加盟店募集 */
    export const MERCHANT_RECRUITMENT = '/merchant/recruitment/';
    /** カルモ利用規約 */
    export const TERMS = '/docs/terms/';
    /** 信用情報の訂正および利用停止の申し出手続き */
    export const CREDIT_INFO_CORRECTION = '/docs/credit-info-correction/';
    /** 個人情報保護方針 */
    export const PRIVACY = 'https://nyle.co.jp/privacypolicy/';
    /** コンテンツポリシー */
    export const CONTENTS_POLICY = '/column/newcar/contentpolicy/';
    /** 引用・転載について */
    export const CITATION_REPRINTING_RULES = '/docs/citation-reprinting-rules/';
    /** その他同意事項 */
    export const AGREEMENTS = '/top/agreement/';
    /** 特定商取引法に基づく表記 */
    export const LAW = '/docs/law/';
    /** 損害保険代理店等における勧誘方針 */
    export const SOLICITATIOONPOLICY = '/docs/solicitationpolicy/';
    /** 取扱い保険会社／推奨販売に関する方針 */
    export const INSURANCESALESPOLICY = '/docs/insurance-sales-policy/';
    /** 引用・転載について */
    export const CITATIONREPRINTINGRULES = '/docs/citation-reprinting-rules/';
    /** 自動車保険 */
    export const CAR_INSURANCE = '/docs/carinsurance/';
    /** スタッフ紹介 */
    export const STAFF = '/docs/staff/';
    /** 社会貢献活動 */
    export const SOCIAL_CONTRIBUTIONS = '/docs/social-contributions/';
    /** カーリースとは */
    export const ABOUT_CARLEASE = '/docs/carlease/';
    /** カルモマガジン */
    export const MAGAZINE = 'https://car-mo.jp/mag/';
    /** FAQ */
    export const FAQ = '/docs/faq/';
    /** お問合せ */
    export const CONTACT = '/contact/';
    /** 定額カルモくんとは？ */
    export const ABOUT_CARMO = '/docs/about/';
    /** 新車をさがす */
    export const NEWCAR_LINEUP_PRICE1 = '/lineup/list/?term=11&price=1';
    /** 新車をさがす 1万円台 */
    export const NEWCAR_LINEUP_PRICE2 = '/lineup/list/?term=11&price=2';
    /** 新車をさがす 2万円台 */
    export const NEWCAR_LINEUP_PRICE3 = '/lineup/list/?term=11&price=3';
    /** 新車をさがす 3万円台 */
    export const NEWCAR_LINEUP = '/lineup/';
    /** 中古車をさがす */
    export const USEDCAR_LINEUP = '/usedcar/lineup/';
    /** 納車までの流れ */
    export const CONTRACT_FLOW = '/docs/contract/';
    /** メンテナンスプラン */
    export const MAINTENANCE = '/docs/maintenance/';
    /** もらえるオプション */
    export const MORAERU = '/docs/moraeru/';
    /** 値段・料金について */
    export const FEE = '/docs/fee/';
    /** はじめての方へ */
    export const FIRST_GUIDE = '/docs/first-guide/';
    /** アプリ紹介ページ_安心要素訴求 */
    export const APP_REASSURANCE = '/docs/app/';
    /** お知らせ一覧 ルーティング */
    export const INFORMATION = '/information/';
    /** お知らせ もらえるプランの取り扱い開始 */
    export const INFO_20190214 = '/docs/info/20190214/';
    /** お知らせ ゴールデンウィーク休業のご案内 */
    export const INFO_20190409 = '/docs/info/20190409/';
    /** お知らせ 賃貸期間に10年、11年を追加 */
    export const INFO_20190701 = '/docs/info/20190701/';
    /** お知らせ ［重要］消費税率改定に関するご案内 */
    export const INFO_20190911 = '/docs/info/20190911/';
    /** お知らせ 営業時間変更のお知らせ */
    export const INFO_20191018 = '/docs/info/20191018/';
    /** お知らせ 利用規約改定のお知らせ */
    export const INFO_20191113 = '/docs/info/20191113/';
    /** お知らせ 利用規約改定のお知らせ */
    export const INFO_20200204 = '/docs/info/20200204/';
    /** お知らせ オプション名変更と料金改定のご案内 */
    export const INFO_20200212 = '/docs/info/20200212/';
    /** お知らせ 乗り放題オプション全車種対応のご案内 */
    export const INFO_20210215 = '/docs/info/20210215/';
    /** お知らせ 乗り放題オプション全車種対応のご案内 */
    export const INFO_20210302 = '/docs/info/20210302/';
    /** お知らせ 乗り放題オプションに関するガイドライン改定のお知らせ */
    export const INFO_20210624 = '/docs/info/20210624/';
    /** お知らせ ガイドライン改定のお知らせ */
    export const INFO_20210708 = '/docs/info/20210708/';
    /** お知らせ ゴールデンウィーク期間中の営業日について */
    export const INFO_20220427 = '/docs/info/20220427/';
    export const INFO_20230428 = '/docs/info/20230428/';
    export const INFO_20240426 = '/docs/info/20240426/';
    /** 法人向けフォーム */
    export const CORPORATE_FORM = '/contract/corporate/';
    /** 法人向けフォーム完了ページ */
    export const CORPORATE_FORM_COMPLETE = '/contract/corporate/complete/';
    /** 加盟店舗一覧 */
    export const SHOPLIST = '/shop/shoplist/';
    /** メディア掲載情報 */
    export const MEDIA = 'https://nyle.co.jp/news/media/';

    /** はじめての方へ 中古車 */
    export const USEDCAR_FIRST_GUIDE = '/usedcar/docs/first-guide/';
    /** メンテナンスプラン 中古車 */
    export const USEDCAR_MAINTENANCE = '/usedcar/docs/maintenance/';
    /** 納車までの流れ 中古車 */
    export const USEDCAR_CONTRACT_FLOW = '/usedcar/docs/contract/';
    /** 月額料金に含まれるもの 中古車 */
    export const USEDCAR_PRICING = '/usedcar/docs/pricing/';
    /** FAQ 中古車 */
    export const USEDCAR_FAQ = '/usedcar/docs/faq/';
    /** お問合せ 中古車 */
    export const USEDCAR_CONTACT = '/usedcar/contact/';

    /** 404 NOT FOUND */
    export const NOT_FOUND = '/404/';

    /** 外部リンク */
    /** カーリースのメリット・デメリット */
    export const MERIT_DEMERIT = 'https://car-mo.jp/mag/category/tips/carlease/advantages_disadvantages/';
    /** ナイルホームページ */
    export const COMPANY_ABOUT = '/docs/company/';
    /** 法人問い合わせ(googleフォーム) */
    export const CORPORATE_GOOGLE_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSf0JxT3eHk23n9yQ0xmbp3ivKbdx3xuQpeIJLF5qXqgEA_QaA/viewform';
    /* 公式LINEアカウント */
    export const LINE_ACCOUNT = 'https://liff.line.me/1657071928-Br8GdoPL?sl=48515928bf';
    /* 資料DLページ遷移先 */
    export const NURTURING_LINK = 'https://carmo-kun.jp/column/newcar/service_introduction_download_ss/';
  }

  export namespace FORM {
    /** 審査フォームエラーメッセージ日本語に翻訳する設定 */
    export const DICT = {
      dictionary: {
        en: {
          custom: {
            lastName: {
              required: '入力して下さい',
              regex:
                '日本語・アルファベット・数字以外の使用できない文字が含まれています。確認の上修正をお願いします',
            },
            firstName: {
              required: '入力して下さい',
              regex:
                '日本語・アルファベット・数字以外の使用できない文字が含まれています。確認の上修正をお願いします',
            },
            lastKana: {
              required: '入力して下さい',
              regex:
                'カタカナ以外の使用できない文字が含まれています。確認の上修正をお願いします',
            },
            firstKana: {
              required: '入力して下さい',
              regex:
                'カタカナ以外の使用できない文字が含まれています。確認の上修正をお願いします',
            },
            year: {
              required: '入力して下さい',
            },
            month: {
              required: '入力して下さい',
            },
            day: {
              required: '入力して下さい',
            },
            phoneNumber: {
              required: '入力して下さい',
              regex: '市外局番が無いようです。確認の上修正をお願いします',
              min: '桁が短いようです。確認の上修正をお願いします',
              max: '桁が長すぎるようです。確認の上修正をお願いします',
            },
            mobileNumber: {
              required: '入力して下さい',
              regex: '市外局番が無いようです。確認の上修正をお願いします',
              min: '桁が短いようです。確認の上修正をお願いします',
              max: '桁が長すぎるようです。確認の上修正をお願いします',
            },
            address3: {
              required: '入力して下さい',
            },
            email: {
              required: '審査結果を受信するアドレスを入力してください',
            },
            question3Answer: {
              required: '１つ以上を選択してください',
            },
            question4Answer: {
              required: '１つ以上を選択してください',
            },
            question4AnswerFree: {
              required: '希望時間帯を入力してください',
            },
            agreement1: {
              required: '定額カルモくんの利用契約に同意してください',
            },
            agreement2: {
              required: 'プライバシーポリシーに同意してください',
            },
            postalCode: {
              required: '入力してください',
              regex: '郵便番号が正しくありません。確認の上修正をお願いします',
            },
            addressPostalCode: {
              required: '入力してください',
              regex: '郵便番号が正しくありません。確認の上修正をお願いします',
            },
            addressAddress3: {
              required: '入力してください',
            },
            residenceYear: {
              required: '入力してください',
              numeric: '整数でお願いします',
              min_value: '1年以上でお願いします（例:4ヶ月→1）',
            },
            holder: {
              required: '入力してください',
            },
            livingPersons: {
              required: '入力してください',
              numeric: '整数でお願いします',
              min_value: 'ご本人様を含む1人以上でお願いします',
            },
            livingMinors: {
              required: '入力してください',
              numeric: '整数でお願いします',
              min_value: '未成年者が同居していない場合は0を入力してください',
            },
            businessYears: {
              required: '入力してください',
              numeric: '整数でお願いします',
            },
            income: {
              required: '入力してください',
              numeric: '整数でお願いします',
              max: '万円単位でお願いします（例：400万円→400）',
            },
            company: {
              required: '入力してください',
            },
            companyPostalCode: {
              required: '入力してください',
              regex: '郵便番号が正しくありません。確認の上修正をお願いします',
            },
            companyAddress3: {
              required: '入力してください',
            },
            companyPhoneNumber: {
              required: '入力して下さい',
              regex: '市外局番が無いようです。確認の上修正をお願いします',
              min: '桁が短いようです。確認の上修正をお願いします',
              max: '桁が長すぎるようです。確認の上修正をお願いします',
            },
            licenseNumber: {
              required: '入力して下さい',
              regex:
                '運転免許証の表面に記載の第○○○○○○号の12桁の番号を入力してください',
              length:
                '免許証番号の桁数に誤りがあるようです。確認して修正をお願いします',
            },
          },
        },
      },
    };

    export const CORPORATE_DICT = {
      dictionary: {
        en: {
          custom: {
            purpose: {
              required: '選択してください',
            },
            companyName: {
              required: '入力してください',
            },
            companyAddress: {
              required: '入力してください',
            },
            name: {
              required: '入力してください',
            },
            nameKana: {
              required: '入力してください',
              regex:
                'カタカナ以外の使用できない文字が含まれています。確認の上修正をお願いします',
            },
            phoneNumber: {
              required: '入力してください',
              regex: '市外局番が無いようです。確認の上修正をお願いします',
              min: '桁が短いようです。確認の上修正をお願いします',
              max: '桁が長すぎるようです。確認の上修正をお願いします',
            },
            email: {
              required: 'ご連絡可能なアドレスを入力してください',
            },
            howToContact: {
              required: '選択してください',
            },
            availableTimeFrom: {
              isLessThanOrEqualTo:
                '入力した時間帯が正しくありません。確認の上修正をお願いします',
            },
            carModel: {
              required: '入力してください',
            },
            bodyColor: {
              required: '入力してください',
            },
            budget: {
              required: '入力してください',
              numeric: '半角の整数で入力してください',
            },
            other: {
              max: '入力可能な文字数は500文字までです',
            },
          },
        },
      },
    };

    export namespace QUESTIONNAIRE {
      export const QUESTION1 = '1.今回のお申込み検討理由について教えてください';
      export const QUESTION1_ANSWERS = [
        '今のクルマと乗り換えのため',
        '現在クルマを持っておらず、新規でクルマを利用するため',
        'セカンドカーとして利用するため',
      ];

      export const QUESTION2 = '2.新しいクルマはいつまでに必要ですか？';
      export const QUESTION2_ANSWERS = [
        '1〜2カ月以内',
        '3カ月以内',
        '6カ月以内',
        '急いでいない',
      ];

      export const QUESTION3 = '1.車の購入手段について、他の選択肢を検討していますか？';
      export const QUESTION3_ANSWERS = [
        '他は特に検討していない',
        '新車ディーラーで購入',
        '中古車ディーラーで購入',
        '他のカーリース事業者（新車）',
        '他のカーリース事業者（中古車）',
        'その他',
      ];
      export const QUESTION4 = '3.審査通過時に、お電話が繋がりやすい時間帯をお教えください。';
      export const QUESTION4_ANSWERS = [
        '10時～13時',
        '13時～16時',
        '16時～20時',
        'その他',
      ];
      export const QUESTION5 = '4.審査通過時に、車のご相談やお見積もりを希望される時期をお教えください。';
      export const QUESTION5_ANSWERS = [
        'できるだけ早く',
        '2週間以内',
        '1ヶ月以内',
        '2ヶ月以内',
        '3ヶ月以内',
        '希望しない',
      ];

      export const PURPOSE = '2.主にどんな用途に車をお使いですか？';
      export const PURPOSE_LIST = [
        '通勤・通学',
        'レジャー',
        '日常の足（買い物や家族の送り迎えなど）',
        '仕事・商用（通勤以外）',
        'その他',
      ];

      export const TRIGGER = '3.おトクにマイカー 定額カルモくんを知ったきっかけを教えてください';
      export const TRIGGER_LIST = [
        'インターネット広告',
        'インターネット記事',
        'インターネット検索',
        'SNS',
        'テレビCM',
        'ラジオCM',
        'チラシ',
        '看板・屋外広告',
        '知人からの紹介',
        '取次加盟店',
        'その他',
      ];
    }

    export const CUSTOM_RULE = {
      checkHolder: (value: string, name: string): boolean => {
        const holderName: string = value.replace(/\s+/g, '');
        if (holderName === name) {
          return false;
        }
        return true;
      },
      checkHolderErrMsg: (value: string, name: string): { message: string} | undefined => {
        const holderName: string = value.replace(/\s+/g, '');
        if (holderName === name) {
          return { message: '所有者名をご記入ください' };
        }
        return undefined;
      },
      testPhoneNumber: (value: string): boolean => {
        if (value.length === 10 && /^0[7-9]0[1-9]/.test(value)) {
          return false;
        } if (value.length === 11 && /^0[0-6]0/.test(value)) {
          return false;
        }
        return true;
      },
      phoneNumberErrMsg: (value: string): { message: string} | undefined => {
        if (value.length === 10 && /^0[7-9]0/.test(value)) {
          return { message: '桁が短いようです。確認の上修正をお願いします' };
        } if (value.length === 11 && /^0[0-6]0/.test(value)) {
          return { message: '桁が長いようです。確認の上修正をお願いします' };
        }
        return undefined;
      },
      testEmail: (value: string): boolean => {
        if (!value.includes('@')) {
          return false;
        } if (/@\w+$/.test(value)) {
          return false;
        } if (/[^a-zA-Z0-9.!#$%&\'*+\/=?^_`{|}~\-@]+/.test(value)) {
          return false;
        } if (!CARMO_CONST.REGEX.MAIL.test(value)) {
          return false;
        }
        return true;
      },
      emailErrMsg: (value: string): { message: string} | undefined => {
        if (!value.includes('@')) {
          return { message: '@がありませんでした。確認の上修正をお願いします' };
        } if (/@\w+$/.test(value)) {
          return {
            message:
              'ドメインにピリオドがありませんでした。確認の上修正をお願いします',
          };
        } if (/[^a-zA-Z0-9.!#$%&\'*+\/=?^_`{|}~\-@]+/.test(value)) {
          return {
            message:
              'メールアドレスに使えない文字が含まれます。確認して修正をお願いします',
          };
        } if (!CARMO_CONST.REGEX.MAIL.test(value)) {
          return {
            message:
              'メールアドレスが正しくありません。確認の上修正をお願いします',
          };
        }
        return undefined;
      },
      testJis: (value: string): boolean => {
        if (!checkConvertibleToShiftJIS(value)) {
          return false;
        }
        return true;
      },
      jisErrMsg: (value: string): { message: string} | undefined => {
        if (!checkConvertibleToShiftJIS(value)) {
          return {
            message:
              '日本語・アルファベット・数字以外の使用できない文字が含まれています。確認の上修正をお願いします',
          };
        }
        return undefined;
      },
      age: (value: number): boolean => value >= NEWCAR_CONST.FORM.YOUNGEST_AGE
        && value <= NEWCAR_CONST.FORM.OLDEST_AGE,
      ageErrMsg: (value: number): { message: string} | undefined => {
        if (
          value >= NEWCAR_CONST.FORM.YOUNGEST_AGE
          && value <= NEWCAR_CONST.FORM.OLDEST_AGE
        ) {
          return undefined;
        }
        return { message: 'お申込の対象は 18 歳以上〜 75 歳以下です。' };
      },
    };

    export const INDUSTRY_LIST = [
      {
        name: '公務員',
        list: [
          '教員',
          '警察署',
          '消防署',
          '自衛隊',
          '交通局',
          '一般行政事務職',
          '郵便局',
          'その他公務',
        ],
      },
      {
        name: '公的資格者',
        list: [
          '医師',
          '弁護士',
          '公認会計士',
          '税理士',
          '大学教授',
          '一級建築士',
          '不動産鑑定士',
          '司法書士',
          'その他公的',
        ],
      },
      {
        name: '一次産業',
        list: ['農業', '漁業', '林業', '畜産業'],
      },
      {
        name: '鉱業',
        list: ['鉱業'],
      },
      {
        name: '土木・建築',
        list: [
          '大工・左官・とび職',
          '建築・工事',
          '測量・調査・設計',
          '塗装',
          '設備工事',
          '建設資材',
        ],
      },
      {
        name: '不動産',
        list: ['不動産'],
      },
      {
        name: '金融',
        list: [
          '銀行・信金・信組',
          '保険（生命）',
          '保険（損害）',
          '保険代理業',
          '証券',
          'クレジット・リース・レンタル',
          '質屋',
          'その他金融',
        ],
      },
      {
        name: '運輸・倉庫',
        list: [
          '陸運',
          '海運',
          '航空',
          '鉄道',
          'バス',
          'タクシー',
          '倉庫',
          '梱包・荷造',
        ],
      },
      {
        name: '製造',
        list: [
          '食品',
          '繊維・紙・パルプ',
          '化学',
          '金属',
          '電気機器',
          '輸送用機器',
          '情報・精密機器',
          '石油ガス等エネルギー',
          '印刷',
          'その他製造',
        ],
      },
      {
        name: '卸',
        list: ['貿易', '一般卸・問屋', '仲買', '取引所'],
      },
      {
        name: '小売',
        list: [
          'デパート',
          'スーパー',
          'コンビニエンスストアー',
          'ディスカウントショップ',
          '一般商店',
          '訪問販売',
          '通信販売',
        ],
      },
      {
        name: '情報・通信',
        list: [
          '新聞社',
          '放送',
          '通信',
          '出版',
          '広告',
          '興信・市場調査',
          '情報サービス',
        ],
      },
      {
        name: '旅行・宿泊',
        list: ['旅行業', 'ホテル・旅館', 'レジャー施設'],
      },
      {
        name: 'その他サービス',
        list: [
          '飲食業',
          '遊技場',
          '風俗',
          'スポーツ施設・健康',
          '教育',
          '介護・福祉',
          '宗教',
          '美容・理容',
          '清掃業',
          '警備',
          '整備・修理',
          '人材斡旋',
          'その他サービス',
        ],
      },
      {
        name: '医療機関',
        list: ['病院・医院', '治療'],
      },
      {
        name: '組合・団体',
        list: ['組合・団体'],
      },
      {
        name: '施設・機関',
        list: ['施設・機関'],
      },
    ];

    export const OTHER_LIST = [
      {
        name: '無職・主婦・学生・年金',
        list: ['主婦', '学生', '年金生活者', 'その他無職'],
      },
    ];

    export const POSITION_LIST = [
      '会社役員',
      '正社員（管理職）',
      '正社員',
      '派遣社員・契約社員',
      'アルバイト・パート',
    ];
  }

  /** UI設定 */
  export namespace UI_SETTING {
    /** 画像遅延ロード */
    export const LAZY_LOAD = { effect: 'fadeIn' };
  }

  /** Metaタグ設定 */
  export namespace META {
    /** 基本TITLE */
    export const TITLE = 'マイカーリースならおトクにマイカー 定額カルモくん 新車が格安 月々定額1万円から';
    /** 基本DESCRIPTION */
    // tslint:disable-next-line:max-line-length
    export const DESCRIPTION = '【業界最安水準】頭金・ボーナス払い0円で新車に格安で乗れる。毎月定額、税金コミコミで安心「おトクにマイカー 定額カルモくん」は新車カーリース（車のサブスク）サービスです。メンテナンスも月額定額にできるプラン、契約満了後に車がもらえるプランもご用意。';

    /** リスト表示時のTITLE */
    export const LIST_TITLE = 'カーリース・マイカーリースの検索結果 おトクにマイカー 定額カルモくん';
    /** リスト表示時のDESCRIPTIONの右側 */
    // tslint:disable-next-line:max-line-length
    export const LIST_DESCRIPTION = 'おトクにマイカー 定額カルモくんの検索結果を表示しています。定額カルモくんの自動車リースは、新車に格安で乗れる、NHKなどでも話題の個人向けマイカーリースサービスです。全国産メーカー全車種をラインナップ！格安価格、諸費用込みの月額定額の料金プランで正規ディーラー新車にお乗りいただけます。';

    /** お知らせ もらえるプランの取り扱い開始 のTITLE */
    export const INFO_20190214_TITLE = 'もらえるプランの取り扱い開始 おトクにマイカー 定額カルモくん';
    /** お知らせ もらえるプランの取り扱い開始 のDESCRIPTION */
    // tslint:disable-next-line:max-line-length
    export const INFO_20190214_DESCRIPTION = 'カーリース契約満了後に乗っていた車がもらえる、新しいオプションを追加しました。月々300円(税抜)～の「もらえるプラン」オプションをご契約頂くことで、リース契約満了後に追加料金0円で車がもらえます。';

    /** お知らせ ゴールデンウィーク休業のご案内 のTITLE */
    export const INFO_20190409_TITLE = 'ゴールデンウィーク休業のご案内 おトクにマイカー 定額カルモくん';
    /** お知らせ ゴールデンウィーク休業のご案内 のDESCRIPTION */
    // tslint:disable-next-line:max-line-length
    export const INFO_20190409_DESCRIPTION = '平素は格別のお引き立てを賜り、厚く御礼申し上げます。誠に勝手ながら、下記の期間を休業とさせていただきます。【休業日】2019年4月27日(土)〜2019年5月6日(月)お申込やメールでのお問合せは常時受付しておりますが、休業期間中に頂きましたご連絡へのご返答は、5月7日(火)以降の対応となります。返答までに少しお時間をいただく場合がございますことを、予めご了承下さい。大変ご迷惑をおかけいたしますが、何卒ご了承くださいますようお願い申し上げます。';

    /** お知らせ 賃貸期間に10年、11年を追加 のTITLE */
    export const INFO_20190701_TITLE = '賃貸期間に10年、11年を追加 おトクにマイカー 定額カルモくん';
    /** お知らせ 賃貸期間に10年、11年を追加 のDESCRIPTION */
    export const INFO_20190701_DESCRIPTION = '従来の賃貸期間の1年～9年にくわえて、10年、11年の取扱を開始いたしました。最長11年の賃貸期間は、カーリース業界初の契約形態です。（※）月々のお支払いをさらに抑えることができ、安心して長くお車にお乗りいただけます。（※）2019年7月定額カルモくん調べ';

    /** お知らせ ［重要］消費税率改定に関するご案内 のTITLE */
    export const INFO_20190911_TITLE = '［重要］消費税率改定に関するご案内 おトクにマイカー 定額カルモくん';
    /** お知らせ ［重要］消費税率改定に関するご案内 のDESCRIPTION */
    export const INFO_20190911_DESCRIPTION = '2019年10月1日に予定されている消費税率引き上げに伴い、新税率の適用を受ける部分については新税率(10％)が適用されます。また、契約書記載の消費税額に関わらず、リース開始日(※1)が令和元年10月1日以降となる場合は、初回リース料から新消費税率の10%が適用されます。';

    /** お知らせ 営業時間変更のお知らせ のTITLE */
    export const INFO_20191018_TITLE = '営業時間変更のお知らせ おトクにマイカー 定額カルモくん';
    /** お知らせ 営業時間変更のお知らせ のDESCRIPTION */
    export const INFO_20191018_DESCRIPTION = '10月18日(金)のみ社内研修のため16時までの営業とさせていただきます。お客様にはご不便をおかけいたしますが、何卒ご理解いただきますようお願い申し上げます。';

    /** お知らせ ［重要］消費税率改定に関するご案内 のTITLE */
    export const INFO_20191113_TITLE = '利用規約変更のお知らせ';
    /** お知らせ ［重要］消費税率改定に関するご案内 のDESCRIPTION */
    export const INFO_20191113_DESCRIPTION = 'リース自動車（中古車）のサービス開始に伴い、利用規約を改訂いたしました。';

    /** お知らせ ［重要］消費税率改定に関するご案内 のTITLE */
    export const INFO_20200204_TITLE = '利用規約変更のお知らせ おトクにマイカー 定額カルモくん';
    /** お知らせ ［重要］消費税率改定に関するご案内 のDESCRIPTION */
    export const INFO_20200204_DESCRIPTION = 'サービス名称の変更に伴い、利用規約を改訂いたしました。';

    /** お知らせ オプション名変更と料金改定のご案内 のTITLE */
    export const INFO_20200212_TITLE = 'オプション名変更と料金改定のご案内 おトクにマイカー 定額カルモくん';
    /** お知らせ オプション名変更と料金改定のご案内 のDESCRIPTION */
    export const INFO_20200212_DESCRIPTION = '2020年2月12日より、「もらえるプラン」を「乗り放題オプション」へ名称を変更し、オプション料金を改定いたしました。乗り放題オプションとは、リース契約満了後に追加料金0円で車がもらえる、月々500円のオプションです。';

      /** お知らせ 乗り放題オプション全車種対応のご案内 のTITLE */
    export const INFO_20210215_TITLE = '乗り放題オプション全車種対応のご案内 | おトクにマイカー 定額カルモくん';
    /** お知らせ 乗り放題オプション全車種対応のご案内 のDESCRIPTION */
    export const INFO_20210215_DESCRIPTION = '2021年2月15日より、「乗り放題オプション」を全車種に対応いたします。乗り放題オプションとは、リース契約満了後に追加料金0円で車がもらえる、月々500円のオプションです。';

      /** お知らせ 210302_利用規約改定 のTITLE */
    export const INFO_20210302_TITLE = '利用規約改定のお知らせ';
    /** お知らせ 210302_利用規約改定 のDESCRIPTION */
    export const INFO_20210302_DESCRIPTION = '新車が簡単ネット申し込みで頭金・ボーナス払い0円の完全月額定額1万円台から。業界最長11年リースや契約満了後に車がもらえるプランなどおトクなカーリースなら「おトクにマイカー 定額カルモくん」におまかせ。';

    /** お知らせ 210624_乗り放題オプションに関するガイドライン改定のお知らせ のTITLE */
    export const INFO_20210624_TITLE = '乗り放題オプションに関するガイドライン改定のお知らせ | おトクにマイカー 定額カルモくん';
    /** お知らせ 210624_乗り放題オプションに関するガイドライン改定のお知らせ のDESCRIPTION */
    export const INFO_20210624_DESCRIPTION = '2021年7月8日より、乗り放題オプションに関するガイドラインを改定いたします。';

    /** お知らせ 210708_ガイドライン改定のお知らせ のTITLE */
    export const INFO_20210708_TITLE = 'ガイドライン改定のお知らせ  | おトクにマイカー 定額カルモくん';
    /** お知らせ 210708_ガイドライン改定のお知らせ のDESCRIPTION */
    export const INFO_20210708_DESCRIPTION = '2021年7月8日より、無償譲渡プランに関するガイドラインを改定いたしました。';

    /** お知らせ ゴールデンウィーク期間中の営業日について のTITLE */
    export const INFO_20220427_TITLE = 'ゴールデンウィーク期間中の営業日について  | おトクにマイカー 定額カルモくん';
    export const INFO_20230428_TITLE = 'ゴールデンウィーク期間中の営業日について  | おトクにマイカー 定額カルモくん';
    export const INFO_20240426_TITLE = 'ゴールデンウィーク期間中の営業日について  | おトクにマイカー 定額カルモくん';

    /** お知らせ ゴールデンウィーク期間中の営業日について のDESCRIPTION */
    export const INFO_20220427_DESCRIPTION = '平素は格別のお引き立てを賜り、厚く御礼申し上げます。誠に勝手ながら、4月29日〜5月1日、5月3日〜5月5日を休業日とさせていただきます。なお、5月2日は通常営業いたします。';
    export const INFO_20230428_DESCRIPTION = '平素は格別のお引き立てを賜り、厚く御礼申し上げます。誠に勝手ながら、4月29日〜4月30日、5月3日〜5月7日を休業日とさせていただきます。なお、5月1日、2日は通常営業いたします。';
     export const INFO_20240426_DESCRIPTION = '平素は格別のお引き立てを賜り、厚く御礼申し上げます。誠に勝手ながら、4月26日〜4月29日、5月3日〜5月6日を休業日とさせていただきます。なお、4月30日、5月1日、2日は通常営業いたします。';

    export const OGP_DEFAULT_TITLE = 'マイカーリースならおトクにマイカー 定額カルモくん 新車が格安 月々定額1万円から';

    export const OGP_DEFAULT_DESCRIPTION = '【新車がおてごろ毎月定額、11年リースは定額カルモくんだけ】定額カルモくんのカーリースは、新車に格安で乗れる、NHKなどでも話題の個人向け自動車リースサービスです。全国産メーカー全車種をラインナップ！税金・手数料コミの月額定額料金でおトクに新車にお乗りいただけます。';

    export const OGP_DEFAULT_URL = 'https://carmo-kun.jp';

    export const OGP_DEFAULT_IMAGE = 'https://carmo-kun.jp/img/ogimage/default_ogimage.png';

    export async function initOGP(): Promise<void> {
      Util.setMeta(NEWCAR_DEFINE.META.TITLE, NEWCAR_DEFINE.META.DESCRIPTION);

      const defaultOgpList = [
        { property: 'title', content: NEWCAR_DEFINE.META.OGP_DEFAULT_TITLE },
        {
          property: 'description',
          content: NEWCAR_DEFINE.META.OGP_DEFAULT_DESCRIPTION,
        },
        { property: 'url', content: NEWCAR_DEFINE.META.OGP_DEFAULT_URL },
        { property: 'image', content: NEWCAR_DEFINE.META.OGP_DEFAULT_IMAGE },
      ];
      const defaultTwitterOgpList = [
        { name: 'title', content: NEWCAR_DEFINE.META.OGP_DEFAULT_TITLE },
        {
          name: 'description',
          content: NEWCAR_DEFINE.META.OGP_DEFAULT_DESCRIPTION,
        },
        { name: 'image', content: NEWCAR_DEFINE.META.OGP_DEFAULT_IMAGE },
      ];
      defaultOgpList.forEach((ogp) => Util.setOgp(ogp));
      defaultTwitterOgpList.forEach((ogp) => Util.setTwitterOgp(ogp));
    }

    /** 新車一覧タイトルの接尾辞 */
    const LINEUP_TITLE_POSTFIX = '｜おトクにマイカー 定額カルモくん';
    /** 新車一覧ディスクリプションの接尾辞 */
    const LINEUP_DESCRIPTION_POSTFIX = '新車が簡単ネット申し込みで頭金・ボーナス払い0円の完全月額定額1万円台から。業界最長11年リースや契約満了後に車がもらえるプランなどおトクなカーリースなら「おトクにマイカー 定額カルモくん」におまかせ。';
    /** 新車一覧の人気順だけ選択されている状態のディスクリプション */
    const LINEUP_POPULAR_DESCRIPTION = '完全月額定額で乗れる新車一覧 人気順です。おトクにマイカー 定額カルモくんなら新車が簡単ネット申し込みで頭金・ボーナス払い0円の完全月額定額1万円台から。業界最長11年リースや契約満了後に車がもらえるプランなどおトクなカーライフをご提供します。';

    /**
     * 新車一覧のタイトル・ディスクリプションで使うテキスト
     * @param makerIds メーカーID一覧
     * @param bodyTypeIds ボディタイプID一覧
     * @param freeText フリーテキスト
     */
    export function LINEUP_TD_TEXT(
      makerIds: CARMO_CONST.MAKER.ID[] = [],
      bodyTypeIds: CARMO_CONST.BODY_TYPE.ID[] = [],
      freeText?: string,
    ): string {
      if (freeText) {
        return `「${freeText}」の`;
      } if (makerIds.length > 0 || bodyTypeIds.length > 0) {
        const makerNames = CARMO_CONST.MAKER.DATA.filter((data) => makerIds.includes(data.id)).map((data) => data.name);
        const bodyTypeNames = CARMO_CONST.BODY_TYPE.DATA.filter((data) => bodyTypeIds.includes(data.id)).map((data) => data.name);

        const makerText = makerNames.join('・');
        const bodyTypeText = bodyTypeNames.join('・');
        return (makerText ? `${makerText}の` : '') + (bodyTypeText ? `${bodyTypeText}の` : '');
      }
      return '';
    }

    /**
     * 新車一覧タイトル
     * @param makerIds メーカーID一覧
     * @param bodyTypeIds ボディタイプID一覧
     * @param sortedBy ソート順
     * @param freeText フリーテキスト
     */
    export function LINEUP_TITLE(
      makerIds: CARMO_CONST.MAKER.ID[] = [],
      bodyTypeIds: CARMO_CONST.BODY_TYPE.ID[] = [],
      sortedBy: NEWCAR_CONST.SORT = NEWCAR_CONST.SORT.PRICE,
      freeText?: string,
    ): string {
      return `${LINEUP_TD_TEXT(makerIds, bodyTypeIds, freeText)}新車カーリース・サブスク一覧（${NEWCAR_CONST.SORT_NAME[sortedBy]}）${LINEUP_TITLE_POSTFIX}`;
    }

    /**
     * 新車一覧ディスクリプション
     * @param makerIds メーカーID一覧
     * @param bodyTypeIds ボディタイプID一覧
     * @param sortedBy ソート順
     * @param freeText フリーテキスト
     */
    export function LINEUP_DESCRIPTION(
      makerIds: CARMO_CONST.MAKER.ID[] = [],
      bodyTypeIds: CARMO_CONST.BODY_TYPE.ID[] = [],
      sortedBy: NEWCAR_CONST.SORT = NEWCAR_CONST.SORT.PRICE,
      freeText?: string,
    ): string {
      if (
        makerIds.length === 0
          && bodyTypeIds.length === 0
          && sortedBy === NEWCAR_CONST.SORT.POP
          && !freeText
      ) {
        return LINEUP_POPULAR_DESCRIPTION;
      }

      return `${LINEUP_TD_TEXT(makerIds, bodyTypeIds, freeText)}新車一覧 ${NEWCAR_CONST.SORT_NAME[sortedBy]}です。${LINEUP_DESCRIPTION_POSTFIX}`;
    }

    /** クイック審査のTITLE
     * クイック審査 + (車のタイプタイトル) + おトクにマイカー 定額カルモくん
     * @export
     * @param {string} typeTitle 車のタイプタイトル
     * @returns Metaに設定するTitle値
     */
    // 右側
    const QUICK_TITLE_RIGHT = '） おトクにマイカー 定額カルモくん';
    // 左側
    const QUICK_TITLE_LEFT = 'クイック審査（';

    export function QUICK_TITLE(typeTitle: string): string {
      return QUICK_TITLE_LEFT + typeTitle + QUICK_TITLE_RIGHT;
    }

    /** クイック審査のDESCRIPTION
     * (車のタイプタイトル) + (車のタイプ) + 共通
     * @export
     * @param {string} typeTitle 車のタイプタイトル
     * @param {string} typeName 車のタイプ
     * @returns Metaに設定するTitle値
     */
    const QUICK_DESCRIPTION_RIGHT = 'をご希望で、お急ぎの方に特にオススメなカーリースのクイック審査のページです。定額カルモくんのカーリースは、最大49,500円のネット割でおトクに新車にお乗りいただけます。新車、中古車問わず車選びはオンラインならではの個人向け格安マイカーリース「定額カルモくん」にお任せください。';
    export function QUICK_DESCRIPTION(typeTitle: string, typeName: string): string {
      return `（${typeTitle}）${typeName}${QUICK_DESCRIPTION_RIGHT}`;
    }

    export const CONTACT_INFO_TITLE = 'ご連絡先入力 - おトクにマイカー 定額カルモくん';
    export const CONTACT_INFO_DESCRIPTION = '会員情報についてのご連絡先情報入力ページです。';
    export const PERSONAL_INFO_TITLE = '基本情報入力 - おトクにマイカー 定額カルモくん';
    export const PERSONAL_INFO_DESCRIPTION = '会員情報についての基本情報入力ページです。';
    export const REVIEW_INFO_TITLE = '審査情報入力 - おトクにマイカー 定額カルモくん';
    export const REVIEW_INFO_DESCRIPTION = '審査情報についての入力ページです。';
    export const CONFIRM_TITLE = '申込内容確認 - おトクにマイカー 定額カルモくん';
    export const CONFIRM_DESCRIPTION = '申込内容の確認ページです。';
    export const COMPLETION_INFO_TITLE = '審査申込完了 - おトクにマイカー 定額カルモくん';
    export const COMPLETION_INFO_DESCRIPTION = '審査申込の完了ページです。';
    export const CP_SPEEDLOTTERYINFO_TITLE = '審査申込みするだけでもれなく当たる！スピードくじキャンペーン - おトクにマイカー 定額カルモくん';
    export const CP_SPEEDLOTTERYINFO_DESCRIPTION = '【スピードくじキャンペーン 抽選で現金5万円が50名様に！またはAmazonギフト券がハズレなしでその場で当たる！ 12/24まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_SPEEDLOTTERYWINNING_TITLE = '審査申込みするだけでもれなく当たる！スピードくじキャンペーン｜当選画面 - おトクにマイカー 定額カルモくん';
    export const CP_SPEEDLOTTERYWINNING_DESCRIPTION = '【スピードくじキャンペーン 抽選で現金5万円が50名様に！またはAmazonギフト券がハズレなしでその場で当たる！ 12/24まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_SPEEDLOTTERYRESULT_TITLE = '審査申込みするだけでもれなく当たる！スピードくじキャンペーン｜結果画面 - おトクにマイカー 定額カルモくん';
    export const CP_SPEEDLOTTERYRESULT_DESCRIPTION = '【スピードくじキャンペーン 抽選で現金5万円が50名様に！またはAmazonギフト券がハズレなしでその場で当たる！ 12/24まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_NEWYEARLOTINFO_TITLE = '審査申込みで当たる！お年玉くじキャンペーン - おトクにマイカー 定額カルモくん';
    export const CP_NEWYEARLOTINFO_DESCRIPTION = '【お年玉くじキャンペーン 抽選で現金10万円が10名様に！またはAmazonギフト券がハズレなしでその場で当たる！ 1/31まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_NEWYEARLOTWINNING_TITLE = '審査申込みで当たる！お年玉くじキャンペーン｜当選画面 - おトクにマイカー 定額カルモくん';
    export const CP_NEWYEARLOTWINNING_DESCRIPTION = '【お年玉くじキャンペーン 抽選で現金10万円が10名様に！またはAmazonギフト券がハズレなしでその場で当たる！ 1/31まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_NEWYEARLOTRESULT_TITLE = '審査申込みで当たる！お年玉くじキャンペーン｜結果画面 - おトクにマイカー 定額カルモくん';
    export const CP_NEWYEARLOTRESULT_DESCRIPTION = '【お年玉くじキャンペーン 抽選で現金10万円が10名様に！またはAmazonギフト券がハズレなしでその場で当たる！ 1/31まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    // ３周年記念CP title description
    export const CP_THIRDANNIVINFO_TITLE = '現金3万円当たる！3周年記念キャンペーン - おトクにマイカー 定額カルモくん';
    export const CP_THIRDANNIVINFO_DESCRIPTION = '【３周年記念キャンペーン 1,000円分Amazonギフト券が当たるくじ引きに参加！さらに抽選で60名に現金3万円キャッシュバック！3/31まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_THIRDANNIVWINNING_TITLE = '現金3万円当たる！3周年記念キャンペーン｜くじ引き結果 - おトクにマイカー 定額カルモくん';
    export const CP_THIRDANNIVWINNING_DESCRIPTION = '【３周年記念キャンペーン 1,000円分Amazonギフト券が当たるくじ引きに参加！さらに抽選で60名に現金3万円キャッシュバック！3/31まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_THIRDANNIVRESULT_TITLE = '現金3万円当たる！3周年記念キャンペーン｜当選画面 - おトクにマイカー 定額カルモくん';
    export const CP_THIRDANNIVRESULT_DESCRIPTION = '【３周年記念キャンペーン 1,000円分Amazonギフト券が当たるくじ引きに参加！さらに抽選で60名に現金3万円キャッシュバック！3/31まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_THIRDANNIVWCHANCE_TITLE = '現金3万円当たる！3周年記念キャンペーン｜Wチャンス - おトクにマイカー 定額カルモくん';
    export const CP_THIRDANNIVWCHANCE_DESCRIPTION = '【３周年記念キャンペーン 1,000円分Amazonギフト券が当たるくじ引きに参加！さらに抽選で60名に現金3万円キャッシュバック！3/31まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';

    // カーリースデビューCP title description
    export const CP_DEBUT_INFO_TITLE = '審査申込みするだけで最大10万円当たる！カーリースデビュー応援キャンペーン｜くじ引き画面 - おトクにマイカー 定額カルモくん';
    export const CP_DEBUT_INFO_DESCRIPTION = '【期間中に初めて審査申込みの方全員に、当たる！ハズレなしのくじを引いて1等10万円キャッシュバックなど、その場でもれなく当たる、カーリースデビュー応援キャンぺーンです。6/30まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_DEBUT_WINNING_TITLE = '審査申込みするだけで最大10万円当たる！カーリースデビュー応援キャンペーン｜くじ引き結果 - おトクにマイカー 定額カルモくん';
    export const CP_DEBUT_WINNING_DESCRIPTION = '【期間中に初めて審査申込みの方全員に、当たる！ハズレなしのくじを引いて1等10万円キャッシュバックなど、その場でもれなく当たる、カーリースデビュー応援キャンぺーンです。6/30まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';
    export const CP_DEBUT_RESULT_TITLE = '審査申込みするだけで最大10万円当たる！カーリースデビュー応援キャンペーン｜当選画面 - おトクにマイカー 定額カルモくん';
    export const CP_DEBUT_RESULT_DESCRIPTION = '【期間中に初めて審査申込みの方全員に、当たる！ハズレなしのくじを引いて1等10万円キャッシュバックなど、その場でもれなく当たる、カーリースデビュー応援キャンぺーンです。6/30まで】定額カルモくんのカーリースは、NHKなどでも話題の個人向け自動車リースサービスです。国産メーカー全車種をラインナップ、11年リースは定額カルモくんだけ！税金・手数料コミの月額定額料金だからお手頃価格で新車にお乗りいただけます。';

    // 総額150万円分くじ引きCP title description
    export const CP_150MAN_INFO_TITLE = '審査申し込みして当てよう！総額150万円分くじ引きキャンペーン';
    export const CP_150MAN_INFO_DESCRIPTION = '期間中に初めて審査申込みの方全員にチャンス！1等10万円キャッシュバックなど、総額150万円分くじ引きキャンぺーン、9/30まで。【業界最安値】新車にとにかく安く乗りたいあなたにピッタリのカーリース「おトクにマイカー 定額カルモくん」です。新車が頭金・ボーナス払い0円、月額定額1万円台から。7年以上の契約なら走行距離も無制限！しかも契約満了後車がもらえるプランも準備。お手頃価格で新車にお乗りいただけます。';
    export const CP_150MAN_RESULT_TITLE = '審査申し込みして当てよう！総額150万円分くじ引きキャンペーン';
    export const CP_150MAN_RESULT_DESCRIPTION = '期間中に初めて審査申込みの方全員にチャンス！1等10万円キャッシュバックなど、総額150万円分くじ引きキャンぺーン、9/30まで。【業界最安値】新車にとにかく安く乗りたいあなたにピッタリのカーリース「おトクにマイカー 定額カルモくん」です。新車が頭金・ボーナス払い0円、月額定額1万円台から。7年以上の契約なら走行距離も無制限！しかも契約満了後車がもらえるプランも準備。お手頃価格で新車にお乗りいただけます。';
  }
  export namespace MAINTENANCE {
    export function PLANS(
      maintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE,
      term: NEWCAR_CONST.TERM.ID,
    ): { [plan: number]: IMaintenancePlan } {
      const PLATINUM = {
        id: NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM,
        name: NEWCAR_CONST.MAINTENANCE.PLAN_NAME(
          NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM,
        ),
        price: 0,
        overview: NEWCAR_CONST.MAINTENANCE.PLAN_OVERVIEW(
          NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM,
        ),
      };
      if (maintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_1
          && term <= NEWCAR_CONST.TERM.ID.YEAR_5
        ) {
          PLATINUM.price = 5980;
        } else if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_6
          && term <= NEWCAR_CONST.TERM.ID.YEAR_8
        ) {
          PLATINUM.price = 6480;
        } else if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_9
          && term <= NEWCAR_CONST.TERM.ID.YEAR_11
        ) {
          PLATINUM.price = 6980;
        }
      }
      if (maintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD) {
        if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_1
          && term <= NEWCAR_CONST.TERM.ID.YEAR_5
        ) {
          PLATINUM.price = 6480;
        } else if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_6
          && term <= NEWCAR_CONST.TERM.ID.YEAR_8
        ) {
          PLATINUM.price = 6980;
        } else if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_9
          && term <= NEWCAR_CONST.TERM.ID.YEAR_11
        ) {
          PLATINUM.price = 7480;
        }
      }
      const GOLD = {
        id: NEWCAR_CONST.MAINTENANCE.PLAN.GOLD,
        name: NEWCAR_CONST.MAINTENANCE.PLAN_NAME(
          NEWCAR_CONST.MAINTENANCE.PLAN.GOLD,
        ),
        price: 0,
        overview: NEWCAR_CONST.MAINTENANCE.PLAN_OVERVIEW(
          NEWCAR_CONST.MAINTENANCE.PLAN.GOLD,
        ),
      };
      if (maintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_1
          && term <= NEWCAR_CONST.TERM.ID.YEAR_5
        ) {
          GOLD.price = 4970;
        } else if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_6
          && term <= NEWCAR_CONST.TERM.ID.YEAR_11
        ) {
          GOLD.price = 4460;
        }
      }
      if (maintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD) {
        if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_1
          && term <= NEWCAR_CONST.TERM.ID.YEAR_5
        ) {
          GOLD.price = 5280;
        } else if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_6
          && term <= NEWCAR_CONST.TERM.ID.YEAR_11
        ) {
          GOLD.price = 4770;
        }
      }

      const SILVER = {
        id: NEWCAR_CONST.MAINTENANCE.PLAN.SILVER,
        name: NEWCAR_CONST.MAINTENANCE.PLAN_NAME(
          NEWCAR_CONST.MAINTENANCE.PLAN.SILVER,
        ),
        price: 0,
        overview: NEWCAR_CONST.MAINTENANCE.PLAN_OVERVIEW(
          NEWCAR_CONST.MAINTENANCE.PLAN.SILVER,
        ),
      };
      if (maintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_1
          && term <= NEWCAR_CONST.TERM.ID.YEAR_5
        ) {
          SILVER.price = 3850;
        } else if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_6
          && term <= NEWCAR_CONST.TERM.ID.YEAR_11
        ) {
          SILVER.price = 3340;
        }
      }
      if (maintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD) {
        if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_1
          && term <= NEWCAR_CONST.TERM.ID.YEAR_5
        ) {
          SILVER.price = 4360;
        } else if (
          term >= NEWCAR_CONST.TERM.ID.YEAR_6
          && term <= NEWCAR_CONST.TERM.ID.YEAR_11
        ) {
          SILVER.price = 3850;
        }
      }

      const NONE = {
        id: NEWCAR_CONST.MAINTENANCE.PLAN.NONE,
        name: NEWCAR_CONST.MAINTENANCE.PLAN_NAME(
          NEWCAR_CONST.MAINTENANCE.PLAN.NONE,
        ),
        price: 0,
        overview: NEWCAR_CONST.MAINTENANCE.PLAN_OVERVIEW(
          NEWCAR_CONST.MAINTENANCE.PLAN.NONE,
        ),
      };

      return {
        [NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM]: PLATINUM,
        [NEWCAR_CONST.MAINTENANCE.PLAN.GOLD]: GOLD,
        [NEWCAR_CONST.MAINTENANCE.PLAN.SILVER]: SILVER,
        [NEWCAR_CONST.MAINTENANCE.PLAN.NONE]: NONE,
      };
    }
  }

  /** URLパラメータの長さ */
  export namespace COL {
    export const MAKER_OR_BODY = 2;
    export const BODY_TYPE = 3;
  }
}

export default NEWCAR_DEFINE;
