/* eslint-disable max-len */
import React, { FC } from 'react';

// Component
import Card from '@/newcar/ui/detail/molecule/Card';

// Image
import pointThird from '@/newcar/ui/detail/img/points/img-point-third.png';
import pointThirdAvif from '@/newcar/ui/detail/img/points/img-point-third.avif';
import pointFourth from '@/newcar/ui/detail/img/points/img-point-fourth.png';
import pointFourthAvif from '@/newcar/ui/detail/img/points/img-point-fourth.avif';

import * as styles from '@/newcar/ui/detail/organism/ChosenPointsMovie.module.styl';

const ChosenPointsMovie:FC = () => (
  <section className="container">
    <Card className={styles.isChosenPoints}>
      <h2 className="title is-3 has-text-centered">
        定額カルモくんが選ばれる
        <br />
        5つのポイント
      </h2>
      <div className={styles.appealPointContainer}>
        <div className={`container is-margin-auto ${styles.appealPointCard}`}>
          <div
            className={`${styles.appealPointContent} ${styles.contentsMarginTop}`}
          >
            {/* point 1 */}
            <div className={styles.appealPointContentItem}>
              <h3 className={styles.appealPointHeading}>
                業界最安水準！
                <span className={styles.appealPointAsteriskSub}>※1</span>
                <br />
                初期費用0円でずっと定額
              </h3>
              <div className={`${styles.appealPointThumbnailContainer} ${styles.isFirst}`}>
                <iframe
                  className={styles.appealPointMovie}
                  width="1280"
                  height="720"
                  src="https://www.youtube.com/embed/CkS2LNIyMro?controls=0"
                  title="月額定額ってどういうこと？"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className={styles.appealPointText}>
                <span className="has-text-weight-bold">頭金・ボーナス払い０円</span>
                、車検代・メンテ代
                <span className={styles.appealPointAsteriskSub}>※</span>
                ・各種税金・自賠責保険料コミ！急な出費に困ることなく月1万円台〜カーライフを楽しめます。
                <span className={`is-block ${styles.appealPointAsterisk}`}>※メンテナンスプラン加入時</span>
              </p>
            </div>
            {/* //_point 1 */}

            {/* point 2 */}
            <div className={styles.appealPointContentItem}>
              <h3 className={`${styles.appealPointHeading} ${styles.isSecond}`}>
                車がもらえる！
                <span className={styles.appealPointAsteriskSub}>※2</span>
                <br className="is-hidden-mobile" />
                抜群の自由度
              </h3>
              <div className={`${styles.appealPointThumbnailContainer} ${styles.isSecond}`}>
                <iframe
                  className={styles.appealPointMovie}
                  width="1280"
                  height="720"
                  src="https://www.youtube.com/embed/1R3lUMAfY_c?controls=0"
                  title="車がもらえるってどういうこと？"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className={styles.appealPointText}>
                <span className="has-text-weight-bold">車は最終的に返却するかもらうか選べます！</span>
                <span className={styles.appealPointAsteriskSub}>※2</span>
                車をもらう場合、ディーラーで購入するのと変わらない使い勝手だから、借り物感なし！車検に通る範囲でカスタムもOKです。
              </p>
            </div>
            {/* //_point 2 */}

            {/* point 3 */}
            <div className={styles.appealPointContentItem}>
              <h3 className={`${styles.appealPointHeading} ${styles.isThird}`}>
                走行距離無制限！
                <span className={styles.appealPointAsteriskSub}>※3</span>
              </h3>
              <div className={`${styles.appealPointThumbnailContainer} ${styles.isThird}`}>
                <iframe
                  className={styles.appealPointMovie}
                  width="1280"
                  height="720"
                  src="https://www.youtube.com/embed/HCxc0EJ0W0w?controls=0"
                  title="走行距離無制限ってどういうこと？"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
              <p className={styles.appealPointText}>
                <span className="has-text-weight-bold">７年以上のご契約なら毎月の走行距離制限がなくなります！</span>
                毎日の通勤や通学、レジャーなどで頻繁に乗る方は安心してお使いいただけます。
              </p>
            </div>
            {/* //_point 3 */}

            {/* point 4 */}
            <div className={styles.appealPointContentItem}>
              <h3 className={styles.appealPointHeading}>
                長期保証＆保険の安心感
              </h3>
              <div className={styles.appealPointContentMiddle}>
                <div className={`${styles.appealPointThumbnailContainer} ${styles.isFourth}`}>
                  <picture>
                    <source srcSet={pointThirdAvif} type="image/avif" />
                    <img
                      src={pointThird}
                      alt="長期保証＆保険の安心感"
                      loading="lazy"
                      width="705"
                      height="440"
                    />
                  </picture>
                </div>
                <p className={styles.appealPointText}>
                  定額カルモくんのメンテナンスプランは、
                  <span className="has-text-weight-bold">
                    最長11年の安心保証
                    <span className={styles.appealPointAsteriskSub}>※4</span>
                    が付帯
                  </span>
                  します。さらにリース車両に特化した特約がついたカルモ契約者様専用自動車保険もご用意しております。
                </p>
              </div>
            </div>
            {/* //_point 4 */}

            {/* point 5 */}
            <div className={styles.appealPointContentItem}>
              <h3 className={styles.appealPointHeading}>
                接客満足度92.7%
              </h3>
              <div className={styles.appealPointContentMiddle}>
                <div className={`${styles.appealPointThumbnailContainer} ${styles.isFifth}`}>
                  <picture>
                    <source srcSet={pointFourthAvif} type="image/avif" />
                    <img
                      src={pointFourth}
                      alt="接客満足度92.7%"
                      loading="lazy"
                      width="529"
                      height="396"
                    />
                  </picture>
                </div>
                <p className={styles.appealPointText}>
                  お客様のご要望に寄り添うことを第一に、
                  <span className="has-text-weight-bold">メーカー横断で最適なカーライフをご提案</span>
                  いたします。LINE・メール・電話でご相談を承っており、できるだけ早くご連絡をするよう心がけております。
                </p>
              </div>
            </div>
            {/* //_point 5 */}
          </div>

        </div>

        <ul className={`${styles.appealPointNoticeList}`}>
          <li className={styles.appealPointNoticeListItem}>
            ※１）文末の制作日における調査結果に基づく。調査概要はコンテンツポリシー参照。
          </li>
          <li className={styles.appealPointNoticeListItem}>
            ※２）7年以上のご契約でもらえるオプション加入時
          </li>
          <li className={styles.appealPointNoticeListItem}>
            ※３）7年以上のご契約
          </li>
          <li className={styles.appealPointNoticeListItem}>
            ※４）プラチナメンテプラン加入時
          </li>
        </ul>
      </div>
    </Card>
  </section>
);

export default ChosenPointsMovie;
