// extracted by mini-css-extract-plugin
export const isChosenPoints = "ChosenPointsMovie-module--is-chosen-points--1tExc";
export const appealPointContainer = "ChosenPointsMovie-module--appeal-point-container--2hPdJ";
export const appealPointTitle = "ChosenPointsMovie-module--appeal-point-title--1-JcO";
export const appealPointCard = "ChosenPointsMovie-module--appeal-point-card--2dh1y";
export const appealPointContent = "ChosenPointsMovie-module--appeal-point-content--2EPea";
export const appealPointContentItem = "ChosenPointsMovie-module--appeal-point-content-item--1ZRDc";
export const appealPointContentMiddle = "ChosenPointsMovie-module--appeal-point-content-middle--3_TBw";
export const appealPointText = "ChosenPointsMovie-module--appeal-point-text--1CPXz";
export const appealPointThumbnailContainer = "ChosenPointsMovie-module--appeal-point-thumbnail-container--1YOI3";
export const isThird = "ChosenPointsMovie-module--is-third--2ddq7";
export const isFourth = "ChosenPointsMovie-module--is-fourth--1T_wc";
export const appealPointButtonContainer = "ChosenPointsMovie-module--appeal-point-button-container--3VK2V";
export const appealPointButtonItem = "ChosenPointsMovie-module--appeal-point-button-item--39qfV";
export const isSecondBottom = "ChosenPointsMovie-module--is-second-bottom--7hpdt";
export const appealPointHeading = "ChosenPointsMovie-module--appeal-point-heading--3p06u";
export const appealPointAsteriskSub = "ChosenPointsMovie-module--appeal-point-asterisk-sub--2PNvM";
export const isFirst = "ChosenPointsMovie-module--is-first--2Z4kq";
export const isSecond = "ChosenPointsMovie-module--is-second--19gQ1";
export const isFifth = "ChosenPointsMovie-module--is-fifth--2LGPD";
export const appealPointNoticeList = "ChosenPointsMovie-module--appeal-point-notice-list--1pVSm";
export const appealPointNoticeListItem = "ChosenPointsMovie-module--appeal-point-notice-list-item--3AIrT";
export const appealPointAsterisk = "ChosenPointsMovie-module--appeal-point-asterisk--3UIoU";
export const appealPointMovie = "ChosenPointsMovie-module--appeal-point-movie--3pCXD";
export const hasBackgroundBlack = "ChosenPointsMovie-module--has-background-black--2rU5J";
export const topLinkIconArrow = "ChosenPointsMovie-module--top-link-icon-arrow--3xpG7";