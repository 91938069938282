import React, {
  useContext, useEffect, useState,
} from 'react';
import { Link } from 'gatsby';
import dayjs from 'dayjs';
import { generateEventId } from '@/carmo-event-tracker';
import { send } from '@/facebook-event-tracker-v2';

import ContractStoreContext from '@/newcar/ui/common/context/ContractStoreContext';
import EntryBreakdownManagerStoreContext from '@/newcar/ui/common/context/EntryBreakdownManagerStoreContext';
import CARMO_CONST from '@/common/util/const';
import { IS_PROD } from '@/common/core/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import { GTM } from '@/common/util/gtm';
import { addPonta, removePonta } from '@/common/util/ponta';

import { META_TITLES, META_DESCRIPTION } from '@/newcar/ui/contract/config';

import SEO from '@/common/ui/organism/SEO';
import EntryFormError from '@/newcar/ui/contract/organism/EntryFormError';
import { Header } from '@/newcar/ui/contract/components';
import * as styles from '@/newcar/ui/contract/provisional_completion/template/index.module.styl';

import flow1 from '@/newcar/ui/contract/img/provisional_completion/flow/1.png';
import flow2 from '@/newcar/ui/contract/img/provisional_completion/flow/2.png';
import flow3 from '@/newcar/ui/contract/img/provisional_completion/flow/3.png';

// 申込みフォームの完了ページ
const ContractComplete: React.FC = () => {
  // Store
  const entryBreakdownStore = useContext(EntryBreakdownManagerStoreContext);
  entryBreakdownStore.restoreFromSessionStorage();
  const contractStore = useContext(ContractStoreContext);
  const { email } = contractStore.getContract;

  // State
  const [showErrorPage, setShowErrorPage] = useState(false);

  const trackConversion = (resId: string | null) => {
    const reservationId = resId || '';
    const entryBreakdown = entryBreakdownStore.getEntryBreakdown;

    let optionName = '';
    let comma = '';
    if (entryBreakdown && entryBreakdown.carNavi) {
      optionName += `${comma}カーナビ`;
      comma = ',';
    }
    if (entryBreakdown && entryBreakdown.backCamera) {
      optionName += `${comma}バックカメラ`;
      comma = ',';
    }
    if (entryBreakdown && entryBreakdown.etc) {
      optionName += `${comma}ETC`;
      comma = ',';
    }
    if (entryBreakdown && entryBreakdown.floorMat) {
      optionName += `${comma}フロアマット`;
      comma = ',';
    }
    if (entryBreakdown && entryBreakdown.doorVisor) {
      optionName += `${comma}ドアバイザー`;
      comma = ',';
    }
    if (entryBreakdown && entryBreakdown.wantNo) {
      optionName += `${comma}希望ナンバー`;
      comma = ',';
    }
    let quickName = '';
    if (entryBreakdown && entryBreakdown.memo) {
      const matches = entryBreakdown.memo.match(/クイック審査：(.*?\||.*$)/);
      quickName = (matches && matches[1].replace(/\|$/, '')) || 'なし';
    }

    if (reservationId) {
      // 計測スクリプト追加用タグ
      const $scripts = document.getElementById('scripts') as HTMLDivElement;

      // GA
      GTM.dataLayer.push({
        name:
          (entryBreakdown
            && entryBreakdown.grade
            && entryBreakdown.grade.orderName)
          || '',
        category:
          (entryBreakdown && entryBreakdown.car && entryBreakdown.car.name)
          || '',
        sku: `${entryBreakdownStore.months}`,
        transactionID: reservationId,
        transactionTotal: `${entryBreakdownStore.monthlyFee}`,
        option: optionName,
        bodyColor:
          (entryBreakdown
            && entryBreakdown.bodyColor
            && entryBreakdown.bodyColor.name)
          || '',
        givePlan: entryBreakdown.wantCar ? 'あり' : 'なし',
        quickPlan: quickName,
        maintenancePlan:
          (entryBreakdown
            && entryBreakdown.maintenancePlan
            && entryBreakdown.maintenancePlan.name)
          || '',
      });

      /** Facebook広告CV計測 */
      if (IS_PROD) {
        // 本番のみ
        try {
          // CV計測データ生成
          const facebookCustomData = {
            content_ids:
              (entryBreakdown
                && entryBreakdown.car
                && entryBreakdown.car.id__normalized.toString())
              || '',
            content_type: 'product',
            value: '1',
            currency: 'JPY',
          };

          // イベントID生成
          const pageViewEventId = generateEventId();
          const purchaseEventId = generateEventId();

          // Facebook (Pixel)
          const $facebookPixelScript = document.createElement('script');
          $facebookPixelScript.innerHTML = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '2275523129140890');

            fbq('trackSingle', '2275523129140890', 'PageView',
             {}, { eventID: ${JSON.stringify(pageViewEventId)} });
            fbq('trackSingle', '2275523129140890', 'Purchase', ${JSON.stringify(facebookCustomData)}, { eventID: ${JSON.stringify(purchaseEventId)} });
          `;
          const $facebookPixelNoScript = document.createElement('noscript');
          $facebookPixelNoScript.innerHTML = '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=2275523129140890&ev=PageView&noscript=1" />';
          $scripts.appendChild($facebookPixelScript);
          $scripts.appendChild($facebookPixelNoScript);

          // Facebook (Conversion API)
          send({
            event_id: pageViewEventId,
            event_name: 'PageView',
            pixel_id: '2275523129140890',
          });

          const gender = CARMO_CONST.GENDER.findGenderByValue(
            contractStore.form.gender,
          );
          send({
            event_id: purchaseEventId,
            event_name: 'Purchase',
            pixel_id: '2275523129140890',
            custom_data: facebookCustomData,
            cv_data: {
              reservation_id: reservationId.toString(),
              email: contractStore.form.email,
              mobilephone: contractStore.form.phoneNumber,
              gender: gender && gender.LABEL,
              birthday: dayjs()
                .set('year', Number(contractStore.form.year))
                .set('month', Number(contractStore.form.month) - 1)
                .set('date', Number(contractStore.form.day))
                .format('YYYY-MM-DD'),
              last_name: contractStore.form.lastName,
              first_name: contractStore.form.firstName,
              city: contractStore.form.city,
              prefecture: contractStore.form.prefectures,
              zipcode: contractStore.form.postalCode,
            },
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
      /** /Facebook広告CV計測 */

      // レントラックス
      const $rentracksImg = document.createElement('img');
      $rentracksImg.setAttribute(
        'src',
        `https://www.rentracks.jp/secure/e.gifx?sid=3021&pid=4599&price=0&quantity=1&reward=-1&cinfo=${reservationId}`,
      );
      $rentracksImg.setAttribute('width', '1');
      $rentracksImg.setAttribute('height', '1');
      const $rentracksScript = document.createElement('script');
      $rentracksScript.innerHTML = `
        (function(){
          function loadScriptRTCV(callback){
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://www.rentracks.jp/js/itp/rt.track.js?t=' + (new Date()).getTime();
            if ( script.readyState ) {
              script.onreadystatechange = function() {
                if ( script.readyState === 'loaded' || script.readyState === 'complete' ) {
                  script.onreadystatechange = null;
                  callback();
                };
              };
            } else {
              script.onload = function() {
                callback();
              };
            };
            document.getElementsByTagName('head')[0].appendChild(script);
          }

          loadScriptRTCV(function(){
            _rt.sid = 3021;
            _rt.pid = 4599;
            _rt.price = 0;
            _rt.reward = -1;
            _rt.cname = '';
            _rt.ctel = '';
            _rt.cemail = '';
            _rt.cinfo = '${reservationId}';
            rt_tracktag();
          });
        }(function(){}));
      `;

      // A8.net
      const $a8Script1 = document.createElement('script');
      $a8Script1.setAttribute(
        'src',
        'https://statics.a8.net/a8sales/a8sales.js',
      );
      const $a8Script2 = document.createElement('script');
      $a8Script2.innerHTML = `
        a8sales({
          "pid": "s00000019530001",
          "order_number": "${reservationId}",
          "currency": "JPY",
          "items": [
            {
              "code": "a8",
              "price": 15000,
              "quantity": 1
            },
          ],
          "total_price": 15000,
        });
      `;
      const $a8Script3 = document.createElement('script');
      $a8Script3.innerHTML = `
        a8sales({
          "pid": "s00000000062001",
          "order_number": "${reservationId}",
          "currency": "JPY",
          "items": [
            {
              "code": "a8",
              "price": 15000,
              "quantity": 1
            },
          ],
          "total_price": 15000,
        });
      `;
      const $a8ScriptContainer = document.createElement('div');
      $a8ScriptContainer.appendChild($a8Script1);
      $a8Script1.addEventListener('load', () => {
        $a8ScriptContainer.appendChild($a8Script2);
        $a8ScriptContainer.appendChild($a8Script3);
      }); // A8の外部スクリプトを読んでからCV計測スクリプト実行

      // LINE ID対応
      const $lineIdContainer1 = document.createElement('script');
      $lineIdContainer1.type = 'text/javascript';
      $lineIdContainer1.innerHTML = `
        window.kuzen_tag.set_custom_values('visit', {"email": "${contractStore.form.email}"});
        window.kuzen_tag.send('visit');
      `;
      const $lineIdContainer2 = document.createElement('script');
      $lineIdContainer2.innerHTML = `
        window.kuzenTagAsyncInit = function() {
          window.kuzen_tag.add_query_parameter_url([]);
          window.kuzen_tag.set_custom_value('email', '${contractStore.form.email}');
        };
        (function() {
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = 'https://tag-v2.kuzen.io?tracking_code=KT_tNAbXd6kh0FtcA%3D%3D614&line=1';
          script.async = true;
          document.head.appendChild(script);
        })();
      `;

      $scripts.appendChild($rentracksImg);
      $scripts.appendChild($rentracksScript);
      $scripts.appendChild($a8ScriptContainer);
      $scripts.appendChild($lineIdContainer1);
      $scripts.appendChild($lineIdContainer2);

      // Criteo
      const breakdown = entryBreakdownStore.getEntryBreakdown;
      GTM.dataLayer.push({
        event: 'criteo.activate',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CriteoProductIDTrans: [
          {
            id: `${(breakdown && breakdown.car && breakdown.car.id__normalized) || ''}`,
            price: `${
              entryBreakdownStore.monthlyFee
              * (entryBreakdownStore.months || 0)
            }`,
            quantity: '1',
          },
        ],
      });

      // Ponta
      addPonta(reservationId);
    }
  };

  const isError = (): boolean => {
    if (entryBreakdownStore.getEntryBreakdown.car?.id__normalized
      && contractStore.getContract.complete) {
      return false;
    }
    return true;
  };

  // UseEffects
  useEffect(() => {
    setShowErrorPage(isError());

    if (contractStore.getContract.complete) {
      // パラメーターから審査申込ID取得
      const reservationId = new URLSearchParams(window.location.search).get('res');
      trackConversion(reservationId);

      // 申込内訳リセット
      const f = async () => {
        await entryBreakdownStore.clearEntryBreakdown();
      };
      f();

      contractStore.setContract({ key: 'complete', value: false });
    }

    return () => {
      removePonta();
    };
  }, []);

  return (
    <div className={styles.lBody}>
      <SEO
        title={META_TITLES.provisional_completion}
        description={META_DESCRIPTION.provisional_completion}
      />
      <style>
        {/* $rentracksScriptで読み込んだスクリプトが作るタグが画面下部に不自然に表示されてしまうのでスタイルで非表示にする */}
        {`itp_iframe_tag {
          display: none;
        }`}
      </style>
      <Header type="provisional_completion" />
      <div className={styles.trackingScript} id="scripts">
        <span id="a8sales" />
      </div>
      { !showErrorPage ? (
        <main className="container is-padding-top-2 is-padding-bottom-6">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              <div className={`block ${styles.block}`}>
                <h2 className="title is-2 ">お申込ありがとうございました</h2>
                <p>
                  入力された情報で審査が行われます。
                </p>
                <p>
                  お申込み内容に不備がなければ通常翌営業日（最大3営業日）に審査結果をお返しいたします。
                </p>
                <p className="is-margin-bottom-5">
                  4営業日経過しても審査結果の連絡が来ない場合は、
                  <Link to={NEWCAR_DEFINE.PATH.CONTACT} target="_blank" className={`has-text-link ${styles.hasUnderline}`}>お問合せ</Link>
                  よりご連絡ください。
                </p>
                <p>
                  審査通過後も契約前であればグレードやカラー、オプションの変更も可能ですのでお気軽にご相談ください。
                </p>
                <p>
                  なお、今回お申込みいただいた審査申込は契約のお申込みではございません。
                </p>
                <p className="is-margin-bottom-5">
                  リース契約の成立には審査通過後に所定のお手続きが必要ですのでご安心ください。
                </p>
                <p>
                  審査結果をメールでお送りしますので〈support@car-mo.jp〉からのメールを受信できるよう、スマホ等の受信許可設定をお願いいたします。
                </p>
                <p>
                  メールが届かない場合は、迷惑メールフォルダをご確認いただけると幸いです。
                </p>
              </div>
              <section className={`card block ${styles.block} ${styles.card}`}>
                メールアドレス：
                <br />
                <p className="has-text-weight-bold">{email}</p>
              </section>
              <section className={`card block ${styles.isFlow} ${styles.block}`}>
                <h3 className="title is-3">
                  今後の流れ
                </h3>
                <ul className={styles.pFlow__list}>
                  <li>
                    <div>
                      <dl className={styles.pFlow__description}>
                        <dt>審査完了</dt>
                      </dl>
                      <p>
                        審査が完了次第、主要連絡方法を通じたご連絡をいたします。
                      </p>
                      <div className="has-text-centered">
                        <img
                          src={flow1}
                          alt="審査のイメージ"
                          className="u-fluid"
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <dl className={styles.pFlow__description}>
                        <dt>本契約</dt>
                      </dl>
                      <p>
                        契約書含む必要書類を郵送でお届けします。必要事項を記入後、ご返送ください。
                      </p>
                      <div className="has-text-centered">
                        <img
                          src={flow2}
                          alt="本契約のイメージ"
                          className="u-fluid"
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <dl className={styles.pFlow__description}>
                        <dt>車両受け取り</dt>
                      </dl>
                      <p>
                        ご自宅まで車をお届けします。素敵なカーライフをお楽しみください。
                      </p>
                      <div className="has-text-centered">
                        <img src={flow3} alt="車両受け取り" className="u-fluid" />
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
              <section className={`card block ${styles.card} ${styles.block}`}>
                <h3 className="title is-3">審査完了後よく見られているページ</h3>
                <div>
                  <div>
                    <Link
                      to={NEWCAR_DEFINE.PATH.ABOUT_CARLEASE}
                      className="c-link--arrow"
                      data-type="normal"
                    >
                      <span className="c-link__arrow c-arrow" />
                      カーリースとは
                    </Link>
                  </div>
                  <div>
                    <Link to={NEWCAR_DEFINE.PATH.FAQ} className="c-link--arrow" data-type="normal">
                      <span className="c-link__arrow c-arrow" />
                      よくあるご質問
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={NEWCAR_DEFINE.PATH.CONTRACT_FLOW}
                      className="c-link--arrow"
                      data-type="normal"
                    >
                      <span className="c-link__arrow c-arrow" />
                      定額カルモくんご納車までの流れ
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      ) : (
        <div>
          <EntryFormError />
        </div>
      )}
    </div>
  );
};

export default ContractComplete;
