import React, { FC } from 'react';
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';

import bnrLp from '@/newcar/ui/top/img/topbanner/lp-bnr.png';
import bnrLpAvif from '@/newcar/ui/top/img/topbanner/lp-bnr.avif';
import uservoice from '@/newcar/ui/top/img/banners/uservoice.png';
import uservoiceAvif from '@/newcar/ui/top/img/banners/uservoice.avif';

const SwiperPc: FC = () => (
  <>
    <Swiper
      modules={[Autoplay, FreeMode, Pagination]}
      autoplay
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        769: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 1,
        },
      }}
      pagination={{
        clickable: true,
        el: '#pageDotsPc',
      }}
      loop
      spaceBetween={32}
      slidesPerView={6}
      speed={500}
    >
      <SwiperSlide>
        <a href="/pr/2025/newyear/">
          <picture>
            <source srcSet={bnrLpAvif} type="image/avif" />
            <img
              src={bnrLp}
              alt="最大3万円が当たる！お年玉キャンペーン2025"
              width="523"
              height="93"
            />
          </picture>
        </a>
      </SwiperSlide>
      <SwiperSlide>
        <a href="/uservoice/">
          <picture>
            <source srcSet={uservoiceAvif} type="image/avif" />
            <img
              src={uservoice}
              alt="お客様の声"
              width="523"
              height="92"
            />
          </picture>
        </a>
      </SwiperSlide>
    </Swiper>

    <div id="pageDotsPc" />
  </>
);

export default SwiperPc;
