import React, { FC } from 'react';
import { Link } from 'gatsby';
import * as styles from '@/newcar/ui/contract/organism/EntryFormError.module.styl';

const EntryFormError: FC = () => (
  <main>
    <section className="is-margin-top-4">
      <div className="accent-line-shot has-background-primary" />
      <div className="card is-padding-0">
        <div className="card-content container has-text-centered">
          <h1 className="title has-text-black-ter is-size-3 is-padding-3 is-margin-top-7">サーバーエラー</h1>
          <h2 className="subtitle has-text-black-ter is-size-4 is-padding-3">
            車両データがありません。
            <br className="is-hidden-desktop" />
            おクルマを選択し、もう一度お試しください。
          </h2>
          <Link to="/lineup/" className="button is-large is-fixed-width-button has-text-black-ter is-margin-top-7">
            クルマを選び直す
            <span className={`icon is-small icon-arrow ${styles.linkIconArrow} has-background-primary ${styles.smallSize}`} />
          </Link>
        </div>
      </div>
    </section>
  </main>
);

export default EntryFormError;
